<template>
  <div>
    <div class="k-grid k-grid-toolbar">
      <app-form-modal
        class-icon="icon-add"
        :title-dialog="`${isNew ? 'Adicionar' : 'Editar'} Categoria`"
        :confirmAction="saveInstance"
        :cancelAction="clearData"
        :formValid="!$v.Instance.$error"
        ref="modal"
      >
        <v-text-field
          v-model="Instance.Title"
          :error="$v.Instance.Title.$error"
          @blur="$v.Instance.Title.$touch()"
          label="Nome da categoria"
          required
        ></v-text-field>
        <div v-if="!isNew">
          <v-label>Imagem</v-label>
          <kendo-upload
            ref="upload"
            :multiple="false"
            name="file"
            :validation-allowed-extensions="['.png', '.jpg', '.jpeg']"
            :select="onSelectFile"
          ></kendo-upload>
        </div>
      </app-form-modal>
    </div>
    <Grid
      :id="gridIdentifier"
      :style="{ height: 'auto' }"
      :data-items="Items"
      :columns="Columns"
      :detail="cellTemplate"
      @expandchange="expandChange"
      :expand-field="'expanded'"
      @edit="edit"
      @remove="remove"
    >
      <template slot="photoCell" slot-scope="{ props }">
        <td :class="props.className">
          <img
            v-if="props.dataItem.PhotoSrc"
            :src="
              GetRelativePath(props.dataItem.PhotoSrc, 'w=75&h=75&mode=crop')
            "
            class="rounded--circle img-fluid"
          />
          <img
            v-else
            src="/assets/imgs/no-photo.png?w=75&h=75&mode=crop"
            class="rounded--circle img-fluid"
          />
        </td>
      </template>
      <GridNoRecords> Não existem categorias </GridNoRecords>
    </Grid>
  </div>
</template>

<script>
import Sortable from "sortablejs";
import Vue from "vue";
import * as kendo from "@progress/kendo-ui";
import { required, minLength } from "vuelidate/lib/validators";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import categoriesService from "@/services/api/courseCategoriesService";
import FormModalComponent from "@/components/modals/form-modal";
import EditCommandCell from "@/components/grid/edit-command.js";
import DeleteCommandCell from "@/components/grid/delete-command.js";
import OrderCommandCell from "@/components/grid/order-command.js";
import UploadHelper from "@/utilities/upload";
import UrlBuilder from "@/utilities/url-builder";

const componentInstance = Vue.component("app-categories-grid-component", {
  props: ["dataItem"],
  template: `<app-categories-grid :dataItem="dataItem"></app-categories-grid>`,
});

export default {
  components: {
    "app-form-modal": FormModalComponent,
  },
  props: ["dataItem"],
  data() {
    return {
      Instance: {
        IdCourseCategory: 0,
        Title: "",
        IdParent: null,
        OrderId: 1,
        Photo: null,
      },
      cellTemplate: componentInstance,
      expandedItems: [],
      Items: [],
      Columns: [
        {
          cell: OrderCommandCell,
          filterable: false,
          sortable: false,
          width: 75,
          title: "Ordem",
        },
        { field: "PhotoSrc", title: "Foto", cell: "photoCell", width: 100 },
        { field: "Title", title: "Título" },
        {
          cell: EditCommandCell,
          filterable: false,
          sortable: false,
          width: 75,
          title: "",
        },
        {
          cell: DeleteCommandCell,
          filterable: false,
          sortable: false,
          width: 75,
          title: "",
        },
      ],
    };
  },
  validations: {
    Instance: {
      Title: {
        required,
        minLength: minLength(3),
      },
      OrderId: {
        required,
      },
    },
  },
  computed: {
    isNew() {
      return this.Instance != null && this.Instance.IdCourseCategory == 0;
    },
    gridIdentifier() {
      return `GridCategory${
        this.dataItem !== null ? this.dataItem.IdCourseCategory : 0
      }`;
    },
  },
  methods: {
    GetRelativePath(path, query) {
      return `${UrlBuilder.GetPath(path)}${query ? "?" + query : ""}`;
    },
    onSelectFile(evt) {
      UploadHelper.LoadFile(evt)
        .then((file) => (this.Instance.Photo = file))
        .catch((file) => (this.Instance.Photo = file));
    },
    openModal() {
      this.$refs.modal.open();
    },
    clearData(callBack) {
      this.resetInstance();
      callBack(true);
    },
    edit(item) {
      this.Instance = { ...item.dataItem };
      this.openModal();
    },
    remove(item) {
      this.deleteInstance(item.dataItem);
    },
    resetInstance() {
      this.Instance = {
        IdCourseCategory: 0,
        Title: "",
        IdParent:
          this.dataItem === null ? null : this.dataItem.IdCourseCategory,
        Photo: null,
        OrderId: this.Items.length + 1,
      };
    },
    expandChange(ev) {
      Vue.set(ev.dataItem, ev.target.$props.expandField, ev.value);
    },
    async loadItems() {
      let options = {
        Filters: [],
        Order: [{ Field: "OrderId", Direction: "ASC" }],
      };
      if (this.dataItem) {
        options.Filters.push({
          Field: "IdParent",
          Operation: "eq",
          Value: this.dataItem.IdCourseCategory,
        });
      } else {
        options.Filters.push({
          Field: "IdParent",
          Operation: "eq",
          Value: null,
        });
      }
      await categoriesService
        .Get(options)
        .then((res) => {
          this.Items = res.data.Items;
          this.resetInstance();
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    orderItems(items) {
      categoriesService
        .UpdateOrder(
          this.dataItem !== null ? this.dataItem.IdCourseCategory : null,
          items
        )
        .then(() => {
          notificationServiceBus.showSuccessMessage(
            "Successo!",
            "alterou a ordem com sucesso!"
          );
          this.loadItems();
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    async saveInstance(callBack) {
      if (this.isNew) {
        await categoriesService
          .Create(this.Instance)
          .then((response) => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Criou a categoria com sucesso!"
            );
            this.Items.push(response.data.Instance);
            this.resetInstance();
            callBack(true);
          })
          .catch((error) => {
            callBack(false);
            notificationServiceBus.showError(error.response);
          });
      } else {
        await categoriesService
          .Update(this.Instance)
          .then((response) => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Editou a categoria com sucesso!"
            );
            const index = this.Items.findIndex(
              (s) => s.IdCourseCategory == this.Instance.IdCourseCategory
            );
            if (index >= 0) {
              this.Items[index] = response.data.Instance;
            }
            this.resetInstance();
            callBack(true);
          })
          .catch((error) => {
            callBack(false);
            notificationServiceBus.showError(error.response);
          });
      }
    },
    deleteInstance(item) {
      var id = item.IdCourseCategory;
      kendo.confirm("Tem a certeza que quer apagar a categoria?").done(() => {
        categoriesService
          .Delete(id)
          .then(() => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Apagou a categoria com sucesso!"
            );
            this.Items = this.Items.filter((s) => s.IdCourseCategory !== id);
            this.resetInstance();
          })
          .catch((error) => {
            notificationServiceBus.showError(error.response);
          });
      });
    },
  },
  async created() {
    this.resetInstance();
    this.$v.$touch();
    await this.loadItems();
  },
  mounted() {
    var that = this;
    var element = `${this.gridIdentifier}-Table`;
    this.$el
      .querySelector(
        `#${this.gridIdentifier} > .k-grid-container > .k-grid-content > div > .k-grid-table > tbody`
      )
      .setAttribute("id", element);
    Sortable.create(document.getElementById(element), {
      handle: ".k-action-move",
      animation: 150,
      onEnd: function (evt) {
        var arr = [...that.Items];
        var old = arr[evt.oldIndex];
        arr = arr
          .slice(0, evt.oldIndex)
          .concat(arr.slice(evt.oldIndex + 1, arr.length));
        arr.splice(evt.newIndex, 0, old);
        that.Items = [...[]];
        that.orderItems(arr.map((s) => s.IdCourseCategory));
      },
    });
  },
};
</script>

<style></style>
