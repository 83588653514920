export const Html = {
  TypeId: 1,
  Name: "Editor de conteudo",
};
export const Video = {
  TypeId: 2,
  Name: "Video",
};
export const Powerpoint = {
  TypeId: 3,
  Name: "Apresentação Powerpoint",
};
export const Test = {
  TypeId: 4,
  Name: "Teste",
};
export const Html5 = {
  TypeId: 5,
  Name: "Conteúdo Html5",
};
export const Audio = {
  TypeId: 6,
  Name: "Audio/Podcast",
};
export const CourseContentTypes = [Html, Video, Powerpoint, Test, Html5, Audio];

export function GetTypeNameById(id) {
  for (let index = 0; index < CourseContentTypes.length; index++) {
    if (CourseContentTypes[index].TypeId === id) {
      return CourseContentTypes[index].Name;
    }
  }
  return "";
}
