<template>
  <v-container class="custom-grid">
    <v-layout v-if="portfolio" row wrap>
      <v-flex xs6>
        <v-card>
          <v-card-title>Formações no portfolio</v-card-title>
          <v-card-text class="number"> {{ courses }} </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xs6>
        <v-card>
          <v-card-title>Alunos afetos</v-card-title>
          <v-card-text class="number"> {{ audience }} </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import portfoliosService from "@/services/api/portfoliosService";

export default {
  props: {
    portfolio: { type: Object },
  },
  data() {
    return {
      isLoading: false,
      audience: 0,
      courses: 0,
    };
  },
  methods: {
    async load() {
      this.isLoading = true;
      await portfoliosService
        .GetStatistics(this.portfolio.IdPortfolio)
        .then((res) => {
          this.audience = res.data.AudienceCount;
          this.courses = res.data.CoursesCount;
        })
        .finally(() => (this.isLoading = false));
    },
  },
  async created() {
    await this.load();
  },
};
</script>

<style scoped lang="scss">
.number {
  font-size: 40px;
}
</style>
