<template>
  <section v-if="Course" id="CoursesDetail">
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Enviar Notificação
        </v-card-title>

        <v-card-text>
          <label>Escolha a mensagem</label>
          <div class="mb-5">
            <kendo-dropdownlist v-model="notificationData.IdWorkflowMessage" :data-source="Items" :data-text-field="'Name'" :data-value-field="'IdWorkflowMessage'" :optionLabel="label" class="w-100" />
          </div>
          <!-- hide-details="auto" -->
          <v-text-field class="mb-5" v-model="notificationData.Link" label="Link" :hideDetails="true"></v-text-field>
          <label>Defina os destinatários</label>
          <v-radio-group v-model="notificationData.Mode">
            <v-radio label="Todos os utilizadores que estejam associados ao curso pelos grupos, independente da subscrição neste curso" :value="0"></v-radio>
            <v-radio label="Todos os utilizadores que começaram o curso mas não acabaram" :value="1"></v-radio>
            <v-radio label="Todos os utilizadores que não começaram o curso" :value="2"></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="noticationClick" :loading="areNotificationsBeeingSent">
            Notificar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <app-page-title>
      <v-layout row wrap align-end justify-space-between fill-height>
        <v-flex xl6 align-center>
          <h3 class="size--s12 white--text lighten-3 text-xs-left">
            <i :class="platformColumnIconTemplate(Course)" class="mr-1 size--s18" />
            {{ typeColumnTemplate(Course) }}
          </h3>
          <h1>
            {{ Course.Name }}
          </h1>
          <h3 class="size--s12 white--text lighten-3 text-xs-left mb-2">
            {{ Course.Code }} -
            <v-chip disabled v-if="Course.IsActive" color="green" text-color="white" small>{{ CourseStatus }}</v-chip>
            <v-chip v-else color="red" text-color="white" disabled small>{{
              CourseStatus
            }}</v-chip>
          </h3>
          <v-chip v-if="Course.IsPublic" label color="green darken-2 white--text">Publicado</v-chip>
          <v-chip v-if="Course.IsNovelty" label color="green darken-2 white--text">Novidade</v-chip>
          <v-chip v-if="Course.IsCourseRepeatable" label color="red text-white darken-1">Repetível</v-chip>
          <v-chip v-if="Course.IsFeatured" label color="red text-white darken-1">Em destaque</v-chip>
        </v-flex>

        <v-flex xl6 class="text-xs-right">
          <v-flex xs12 class="mb-10">
            <v-btn outline color="white" to="/catalog/courses" class="mb-10">
              Voltar à listagem <i class="icon icon-arrow-left ml-2"></i></v-btn>
          </v-flex>
          <a :href="GetFrontEndUrl" target="_blank" class="v-btn black white--text font-weight-normal ml-auto mr-2 mt-10">
            Pré-visualizar
            <i class="icon icon-arrow-right ml-2"></i>
          </a>
          <router-link :to="'edit'" class="v-btn info text-white font-weight-normal ml-auto mt-10 mr-2">
            Editar
            <i class="icon icon-arrow-right ml-2"></i>
          </router-link>

          <v-menu offset-y bottom left>
            <template v-slot:activator="{ on }">
              <v-btn flat icon color="white" v-on="on">
                <v-icon>more_horiz</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-tile @click="notifyCourse">
                <v-list-tile-title class="px-3">Notificar</v-list-tile-title>
              </v-list-tile>
              <v-list-tile @click="duplicateCourse">
                <v-list-tile-title class="px-3">Duplicar curso</v-list-tile-title>
              </v-list-tile>
              <v-list-tile @click="deleteCourse">
                <v-list-tile-title class="px-3">Eliminar curso</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
        </v-flex>
      </v-layout>
    </app-page-title>
    <div class="d-flex align-content-stretch detail-wrapper">
      <div class="detail-sidebar">
        <app-sidebar-menu :menus="menus" />
      </div>
      <div class="detail-content">
        <router-view params="route: route" :course="Course" @reloadCourse="onReloadCourse" @modulesChanged="onModulesChanged" @courseChanged="onCourseChanged" />
      </div>
    </div>
  </section>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import CoursesService from "@/services/api/coursesService";
import NotificationsService from "@/services/api/notificationsService";
import workflowMessagesService from "@/services/api/workflowMessagesService";
import SidebarComponent from "@/components/layout/page-menu";
import { CourseTypes } from "@/models/course-types";
import { CoursePlataformTypes } from "@/models/course-plataform-types";
import { FRONTEND_URL } from "@/settings";

export default {
  components: {
    "app-sidebar-menu": SidebarComponent,
  },
  validations: {
    ModuleInstance: {
      Name: {
        required,
        minLength: minLength(4),
      },
    },
  },
  watch: {
    async $route () {
      await this.LoadCourse(this.$route.params.idCourse);
    },
  },
  computed: {
    CourseStatus: function () {
      return this.Course.IsActive ? "Ativo" : "Não ativo";
    },
    GetFrontEndUrl: function () {
      return FRONTEND_URL + "/courses/detail/" + this.Course.IdCourse;
    },
  },
  data () {
    return {
      label: {
        TypeId: null,
        Name: "Notificação...",
      },
      Items: [],
      areNotificationsBeeingSent: false,
      notificationData: {
        IdCourse: 0,
        IdWorkflowMessage: 0,
        Mode: 0,
        Link: "",
      },
      dialog: false,
      types: CourseTypes,
      plataformTypes: CoursePlataformTypes,
      isInstanceModuleModalOpen: false,
      isLoading: false,
      ModuleInstance: {},
      Course: null,
      menus: [
        {
          Name: "Dashboard",
          Icon: "icon-home",
          Path: "dashboard",
        },
        {
          Name: "Conteúdos",
          Icon: "icon-catalog",
          Path: "contents",
        },
        // {
        //   Name: "Sessões",
        //   Icon: "icon-workflow",
        //   Path: "sessions"
        // },
        {
          Name: "Turmas",
          Icon: "icon-users",
          Path: "classes",
        },
        {
          Name: "Inscrições",
          Icon: "icon-user-check",
          Path: "inscriptions",
        },
        {
          Name: "Comentários",
          Icon: "icon-message",
          Path: "comments",
        },
        // {
        //   Name: "Estatísticas",
        //   Icon: "icon-reports",
        //   Path: "statistics"
        // },
        // {
        //   Name: "Mensagens",
        //   Icon: "icon-message",
        //   Path: "messages"
        // }
      ],
    };
  },
  async created () {
    this.resetModuleInstance();
    this.notificationData.IdCourse = parseInt(this.$route.params.idCourse);
    await this.LoadCourse(this.$route.params.idCourse);
    await this.LoadNotificationMessages();
  },
  methods: {
    notifyCourse () {
      this.dialog = true;
    },
    async deleteCourse () {
      await CoursesService.Delete(this.Course.IdCourse)
        .then(() => {
          notificationServiceBus.showSuccessMessage(
            "Successo",
            "Eliminou a formação com sucesso"
          ),
            //TODO change router navigate
            this.$router.push("/catalog/courses");
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    async duplicateCourse () {
      await CoursesService.Duplicate(this.Course.IdCourse)
        .then((res) => {
          notificationServiceBus.showSuccessMessage(
            "Successo",
            "Duplicou formação com sucesso"
          ),
            (window.location = `/catalog/courses/${res.data.Instance.IdCourse}/edit`);
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    typeColumnTemplate (e) {
      const type = this.types.find((s) => s.TypeId === e.TypeId);
      return type ? type.Name : "";
    },
    platformColumnIconTemplate (e) {
      const type = this.plataformTypes.find(
        (s) => s.TypeId === e.PlataformTypeId
      );
      return type ? type.Icon : "";
    },
    resetModuleInstance () {
      this.ModuleInstance = {
        IdCourseModule: 0,
        IdCourse: this.$route.params.idCourse,
        Name: "",
        Description: "",
        OrderId: 1,
      };
    },
    async onReloadCourse () {
      await this.LoadCourse(this.$route.params.idCourse);
    },
    onCourseChanged (updated) {
      this.Course = updated;
    },
    onModulesChanged (modules) {
      this.Course.Modules = [...modules];
      this.Course.ModulesIds = modules.map((s) => s.IdCourseModule);
    },
    async LoadCourse (id) {
      await CoursesService.GetSingle(id, { Detailed: true })
        .then((response) => (this.Course = response.data.Instance))
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    async LoadNotificationMessages () {
      await workflowMessagesService
        .Get()
        .then((res) => {
          this.Items = res.data.Items;
          this.notificationData.IdWorkflowMessage =
            this.Items[0].IdWorkflowMessage;
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    async noticationClick () {
      this.areNotificationsBeeingSent = true;
      await NotificationsService.Courses(this.notificationData)
        .then((res) => {
          this.dialog = false;
          notificationServiceBus.showSuccessMessage(
            "Successo",
            "Notificações envidas para " +
            res.data.UsersCount +
            " utilizador(es)"
          );
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        })
        .finally(() => (this.areNotificationsBeeingSent = false));
    },
  },
};
</script>

<style scoped></style>
