<template>
  <section id="CategoriesIndex">
    <app-page-title>
      <v-layout row justify-space-between align-end>
        <v-flex>
          <h1>Listagem de Inquéritos</h1>
        </v-flex>
        <v-flex class="text-right">
          <app-form-modal
            class-icon="icon-add"
            :title-dialog="`${isNew ? 'Adicionar' : 'Editar'} Inquérito`"
            :confirmAction="saveInstance"
            :cancelAction="clearData"
            :formValid="!$v.Instance.$error && Instance.Name.length > 0"
            ref="modal"
          >
            <v-layout row wrap>
              <v-flex xs12>
                <v-text-field
                  label="Nome do inquérito"
                  v-model="Instance.Name"
                  :error="$v.Instance.Name.$error"
                  @blur="$v.Instance.Name.$touch()"
                  required
                ></v-text-field>
                <v-text-field
                  label="Subtitulo do inquérito"
                  v-model="Instance.Subtitle"
                  :error="$v.Instance.Name.$error"
                  @blur="$v.Instance.Name.$touch()"
                  required
                ></v-text-field>
                <v-select
                  label="Tipo de inquérito"
                  v-model="Instance.TypeId"
                  item-text="Name"
                  item-value="TypeId"
                  :items="types"
                >
                </v-select>
              </v-flex>
              <v-flex xs6 v-if="!isNew"> </v-flex>
            </v-layout>
          </app-form-modal>
        </v-flex>
      </v-layout>
    </app-page-title>
    <v-container>
      <Grid
        :style="{ height: 'auto' }"
        :data-items="Items"
        :columns="Columns"
        @edit="edit"
        @remove="remove"
      >
        <template slot="linkCell" slot-scope="{ props }">
          <td :class="props.className">
            <router-link :to="`/catalog/surveys/${props.dataItem.IdSurvey}`">{{
              props.dataItem[props.field]
            }}</router-link>
          </td>
        </template>
        <template slot="TypeCell" slot-scope="{ props }">
          <td :class="props.className">
            {{ GetTypeName(props.dataItem) }}
          </td>
        </template>
        <template slot="detailCell" slot-scope="{ props }">
          <td :class="props.className">
            <router-link
              class="k-button k-button-icontext"
              :to="`/catalog/surveys/${props.dataItem.IdSurvey}/edit`"
            >
              <span class="icon-mode_edit"></span>
            </router-link>
          </td>
        </template>
        <GridNoRecords>Não existem inquéritos</GridNoRecords>
      </Grid>
    </v-container>
  </section>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import * as kendo from "@progress/kendo-ui";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import surveyService from "@/services/api/surveyService";
import FormModalComponent from "@/components/modals/form-modal-large";
import DeleteCommandCell from "@/components/grid/delete-command.js";
import { SurveyTypes, GetTypeNameById } from "@/models/surveys-types";
import UrlBuilder from "@/utilities/url-builder";

export default {
  components: {
    "app-form-modal": FormModalComponent,
  },
  data() {
    return {
      types: SurveyTypes,
      Instance: {},
      Items: [],
      Columns: [
        { field: "Name", title: "Nome", cell: "linkCell" },
        {
          field: "TypeId",
          title: "Tipo",
          cell: "TypeCell",
        },
        {
          headerCell: this.noSortableFunction,
          cell: "detailCell",
          width: 75,
          title: "",
        },
        {
          cell: DeleteCommandCell,
          filterable: false,
          sortable: false,
          width: 75,
          title: "",
        },
      ],
    };
  },
  validations: {
    Instance: {
      Name: {
        required,
        minLength: minLength(3),
      },
    },
  },
  computed: {
    isNew() {
      return this.Instance != null && this.Instance.IdSurvey == 0;
    },
  },

  methods: {
    GetTypeName(item) {
      return GetTypeNameById(item.TypeId);
    },
    GetRelativePath(path, query) {
      return `${UrlBuilder.GetPath(path)}${query ? "?" + query : ""}`;
    },
    openModal() {
      this.$refs.modal.open();
    },
    deleteInstance(item) {
      var id = item.IdSurvey;
      kendo.confirm("Tem a certeza que quer apagar o inquérito?").done(() => {
        surveyService
          .Delete(id)
          .then(() => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Apagou o inquérito com sucesso!"
            );
            this.Items = this.Items.filter((s) => s.IdSurvey !== id);
            this.resetInstance();
          })
          .catch((error) => {
            notificationServiceBus.showError(error.response);
          });
      });
    },
    edit(item) {
      this.Instance = { ...item.dataItem };
      this.openModal();
    },
    remove(item) {
      this.deleteInstance(item.dataItem);
    },
    resetInstance() {
      this.Instance = {
        IdSurvey: 0,
        Name: "",
        TypeId: 1,
      };
    },
    clearData(callBack) {
      this.resetInstance();
      callBack(true);
    },
    async loadItems() {
      let options = {
        Filters: [],
        Order: [],
      };
      await surveyService
        .Get(options)
        .then((res) => {
          this.Items = res.data.Items;
          this.resetInstance();
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    async saveInstance(callBack) {
      if (this.isNew) {
        await surveyService
          .Create(this.Instance)
          .then((response) => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Criou o anúncio com sucesso!"
            );
            this.Items.push(response.data.Instance);
            this.resetInstance();
            callBack(true);
          })
          .catch((error) => {
            callBack(false);
            notificationServiceBus.showError(error.response);
          });
      } else {
        await surveyService
          .Update(this.Instance)
          .then((response) => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Editou o anúncio com sucesso!"
            );
            const index = this.Items.findIndex(
              (s) => s.IdNotice == this.Instance.IdNotice
            );
            if (index >= 0) {
              this.Items[index] = response.data.Instance;
            }
            this.resetInstance();
            callBack(true);
          })
          .catch((error) => {
            callBack(false);
            notificationServiceBus.showError(error.response);
          });
      }
    },
  },
  async created() {
    this.resetInstance();
    await this.loadItems();
  },
  watch: {
    $route() {
      // if (location.hash) {
      //   this.$refs.modal.open();
      // }
    },
  },
  mounted() {
    // if (location.hash) {
    //   this.$refs.modal.open();
    // }
  },
};
</script>

<style></style>
