<template>
  <div class="container__custom--w800">
    <v-tabs grow v-model="currentTab">
      <v-tab :href="`#tab-details`">Detalhes</v-tab>
      <v-tab :href="`#tab-infos`">Informações</v-tab>
      <v-tab :href="`#tab-elearn`">E-Learning</v-tab>
      <v-tab :href="`#tab-report`">Relatório único - anexo C / D</v-tab>
    </v-tabs>
    <v-tabs-items v-model="currentTab">
      <v-form @submit.prevent="updateInstance">
        <!-- TAB DETAILS -->
        <v-tab-item :value="`tab-details`">
          <v-card>
            <v-card-text>
              <div class="form-group custom-grid">
                <v-layout row wrap>
                  <v-flex xs6>
                    <v-text-field label="Código do Formação" :error="$v.Instance.Code.$error" @blur="$v.Instance.Code.$touch()" v-model="Instance.Code" required></v-text-field>
                  </v-flex>
                  <v-flex xs6>
                    <v-label>Tipo de Formação</v-label>
                    <v-list-tile>
                      <v-list-tile-avatar>
                        <v-icon :class="courseTypeIcon" class="size--s21"></v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>{{
      courseTypeName
    }}</v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>
                  </v-flex>
                  <v-flex xs6>
                    <v-select label="Plataforma" :items="plataformTypes" item-text="Name" item-value="TypeId" v-model="Instance.PlataformTypeId"></v-select>
                    <!-- <v-list-tile>
                      <v-list-tile-avatar>
                        <v-icon :class="coursePlataformTypeIcon" class="size--s21"></v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>{{ coursePlataformTypeName }}</v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>-->
                  </v-flex>
                  <v-flex xs6>
                    <v-checkbox label="Formação ativa" :input-value="Instance.IsActive" v-model="Instance.IsActive"></v-checkbox>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field label="Nome" :error="$v.Instance.Name.$error" @blur="$v.Instance.Name.$touch()" v-model="Instance.Name" required></v-text-field>
                  </v-flex>
                  <v-flex xs6>
                    <v-label>Período do Formação</v-label>
                    <app-date-picker v-model="Instance.DateScheduledStart" title="Data Início"></app-date-picker>
                  </v-flex>
                  <v-flex xs6>
                    <v-label>Limite para conclusão</v-label>
                    <app-date-picker v-model="Instance.DateScheduledEnd" title="Data Fim"></app-date-picker>
                  </v-flex>
                  <v-flex xs4>
                    <v-label>Tempo formação (horas)</v-label>
                    <v-text-field solo type="number" v-model="Hours"></v-text-field>
                  </v-flex>
                  <v-flex xs4>
                    <v-label>Tempo minimo (minutos)</v-label>
                    <v-text-field solo type="number" v-model="Instance.MinutesRequired"></v-text-field>
                  </v-flex>
                  <!-- <v-flex xs4>
                    <v-label>Pontos para Gamification</v-label>
                    <v-text-field
                      solo
                      type="number"
                      v-model="Instance.PointsForGamification"
                    ></v-text-field>
                  </v-flex> -->
                  <v-flex xs12>
                    <v-checkbox class="mt-0" label="Possibilidade de editar duração de ações de formação" :input-value="Instance.IsClassesDurationEditable" v-model="Instance.IsClassesDurationEditable"></v-checkbox>
                  </v-flex>
                  <v-flex xs12>
                    <v-label>Repetição</v-label>
                    <!-- <v-checkbox
                      class="mt-0"
                      :input-value="Instance.IsTestRepeatable"
                      label="Possibilidade de refazer teste"
                      v-model="Instance.IsTestRepeatable"
                    >
                    </v-checkbox>-->
                    <v-checkbox class="mt-0" :input-value="Instance.IsCourseRepeatable" label="Possibilidade de refazer Formação (reciclagem)" v-model="Instance.IsCourseRepeatable"></v-checkbox>
                    <div v-if="Instance.IsCourseRepeatable">
                      <v-select label="Tipo de Repetição" :items="CourseRepeatableTypes" item-text="Name" item-value="TypeId" v-model="Instance.RepeatableTypeId"></v-select>
                    </div>
                  </v-flex>
                  <v-flex xs6>
                    <v-label>Fornecedores</v-label>
                    <v-select v-model="Instance.SuppliersIds" :items="suppliers" item-text="Name" item-value="IdSupplier" value="IdSupplier" multiple></v-select>
                  </v-flex>
                  <v-flex xs6>
                    <v-label>Nivel da Formação</v-label>
                    <v-select :items="CourseLevels" item-text="Name" item-value="TypeId" v-model="Instance.LevelId"></v-select>
                  </v-flex>
                  <v-flex xs12>
                    <v-label>Inquéritos</v-label>
                    <v-checkbox class="mt-0" :input-value="Instance.HasSatisfactionSurvey" label="Tem Questionário de Avaliação de Satisfação" v-model="Instance.HasSatisfactionSurvey"></v-checkbox>
                    <v-select v-if="Instance.HasSatisfactionSurvey" item-text="Name" item-value="IdSurvey" clearable v-model="Instance.IdSatisfactionSurvey" :items="SatisfactionSurveys"></v-select>
                    <v-checkbox class="mt-0" :input-value="Instance.HasAvaliationSurvey" label="Tem questionário de avaliação de impacto (3 meses após conclusão)" v-model="Instance.HasAvaliationSurvey"></v-checkbox>
                    <v-select item-text="Name" item-value="IdSurvey" clearable v-model="Instance.IdAvaliationSurvey" v-if="Instance.HasAvaliationSurvey" :items="AvaliationSurveys">
                    </v-select>
                    <v-checkbox class="mt-0" :input-value="Instance.HasFormationSurvey" label="Tem Grelha de avaliação de formação presencial / live?" v-model="Instance.HasFormationSurvey"></v-checkbox>
                    <v-select item-text="Name" item-value="IdSurvey" clearable v-model="Instance.IdFormationSurvey" v-if="Instance.HasFormationSurvey" :items="FormationSurveys">
                    </v-select>
                  </v-flex>
                  <!-- <v-flex xs12>
                    <v-label>Metodologia de ensino</v-label>
                    <v-select
                      :items="CourseArchitectureTypes"
                      item-text="Name"
                      item-value="TypeId"
                      v-model="Instance.ArchitectureTypeId"
                    ></v-select>
                  </v-flex>
                  <v-flex xs6>
                    <v-label>Tolerância de assiduidade (%)</v-label>
                    <v-text-field
                      solo
                      type="number"
                      v-model="Instance.Attendance"
                    ></v-text-field>
                  </v-flex>-->
                  <v-flex xs12>
                    <v-label>Formações relacionadas</v-label>

                    <course-selector item-text="Name" v-model="Instance.RelatedCoursesIds" :multiple="true"></course-selector>
                    <v-list two-line dense>
                      <v-list-tile v-for="item in this.course.RelatedCourses" :key="item.IdCourse">
                        <v-list-tile-content>
                          <v-list-tile-title>{{ item.Code }}</v-list-tile-title>
                          <v-list-tile-sub-title>
                            {{ item.Name }}
                          </v-list-tile-sub-title>
                        </v-list-tile-content>
                        <v-list-tile-action>
                          <v-icon :color="item.active ? 'black' : 'grey'" @click="removeChip(item)">close</v-icon>
                        </v-list-tile-action>
                      </v-list-tile>
                    </v-list>
                  </v-flex>
                </v-layout>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn color="white" @click.prevent="cancel">Cancelar</v-btn>

              <v-btn color="accent" class="ml-auto" type="submit" :isLoading="isLoading" :disabled="!isFormValid">Alterar</v-btn>

              <!-- <v-btn
                color="accent"
                class="ml-auto"
                type="button"
                @click="nextTab('tab-infos')"
                >Seguinte</v-btn
              >-->
            </v-card-actions>
          </v-card>
        </v-tab-item>
        <!-- TAB INFO -->
        <v-tab-item :value="`tab-infos`">
          <v-card>
            <v-card-text class="custom-grid">
              <v-layout row wrap>
                <v-flex xs6>
                  <v-label>Definir imagem</v-label>
                  <kendo-upload ref="upload" :multiple="false" name="file" :validation-allowed-extensions="['.png', '.jpg', '.jpeg']" :select="onSelectFile"></kendo-upload>
                </v-flex>
                <v-flex xs6 v-if="Instance.PhotoSrc">
                  <v-label>Imagem de capa</v-label>
                  <v-img :src="GetRelativePath(Instance.PhotoSrc)" max-width="300" height="auto"></v-img>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12>
                  <v-label>Descrição</v-label>
                  <kendo-editor v-model="Instance.Description" :resizable-content="true" :resizable-toolbar="true" :value="Instance.Description" :tools="editorTools" style="height: 280px"></kendo-editor>
                </v-flex>
                <v-flex xs12>
                  <v-label>Objectivos</v-label>
                  <kendo-editor v-model="Instance.Objectives" :resizable-content="true" :resizable-toolbar="true" :value="Instance.Objectives" :tools="editorTools" style="height: 280px"></kendo-editor>
                </v-flex>
                <v-flex xs12>
                  <v-label>Conteúdo programático</v-label>
                  <kendo-editor v-model="Instance.ContentsInfo" :resizable-content="true" :resizable-toolbar="true" :value="Instance.ContentsInfo" :tools="editorTools" style="height: 280px"></kendo-editor>
                </v-flex>
                <v-flex xs12>
                  <v-label>Formadores</v-label>
                  <kendo-editor v-model="Instance.Trainers" :resizable-content="true" :resizable-toolbar="true" :value="Instance.Trainers" :tools="editorTools" style="height: 280px"></kendo-editor>
                </v-flex>
                <v-flex xs12>
                  <v-label>Destinatários</v-label>
                  <kendo-editor v-model="Instance.Recipients" :resizable-content="true" :resizable-toolbar="true" :value="Instance.Recipients" :tools="editorTools" style="height: 280px"></kendo-editor>
                </v-flex>
                <v-flex>
                  <v-checkbox :input-value="Instance.IsHighlight" v-model="Instance.IsHighlight" label="Em destaque"></v-checkbox>
                </v-flex>
                <!-- <v-flex xs12>
                  <v-label>Dominio da Formação</v-label>
                  <course-info-selector
                    :items="FormationDomains"
                    @input="saveToCourseInfos"
                    :table="`Domain`"
                    :value="getInfoByCode('Domain')"
                    @remove="removeFromInfos"
                  ></course-info-selector>
                </v-flex>-->
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-btn color="white" @click.prevent="cancel">Cancelar</v-btn>

              <v-btn color="accent" class="ml-auto" type="submit" :isLoading="isLoading" :disabled="!isFormValid">Alterar</v-btn>
              <!-- <v-btn
                v-if="this.Instance.TypeId === 1"
                color="accent"
                class="ml-auto"
                type="button"
                @click="nextTab('tab-elearn')"
                >Seguinte</v-btn
              >
              <v-btn
                v-else
                color="accent"
                class="ml-auto"
                type="button"
                @click="nextTab('tab-report')"
                >Seguinte</v-btn
              >-->
            </v-card-actions>
          </v-card>
        </v-tab-item>
        <!-- TAB ELEARN -->
        <v-tab-item :value="`tab-elearn`">
          <v-card>
            <v-card-text class="custom-grid">
              <v-layout row wrap>
                <v-flex xs12>
                  <v-label>Categorias</v-label>
                  <v-select item-text="Title" :items="Categories" v-model="Instance.CategoriesIds" item-value="IdCourseCategory" :clearable="true" multiple></v-select>
                </v-flex>
                <v-flex xs6>
                  <app-date-picker v-model="Instance.DatePublished" title="Data de Publicação do Formação"></app-date-picker>
                </v-flex>
                <v-flex xs12>
                  <v-label>Periodo de Inscrição</v-label>
                </v-flex>
                <v-flex xs6>
                  <app-date-picker v-model="Instance.DateInscriptionsStart" title="Data Início"></app-date-picker>
                </v-flex>
                <v-flex xs6>
                  <app-date-picker v-model="Instance.DateInscriptionsEnd" title="Data Fim"></app-date-picker>
                </v-flex>
                <v-flex xs12>
                  <v-checkbox class="mt-0" :input-value="Instance.IsTestRepeatable" label="Possibilidade de refazer teste" v-model="Instance.IsTestRepeatable"></v-checkbox>
                </v-flex>
                <v-flex xs12>
                  <v-label>Cálculo de avaliação</v-label>
                  <v-radio-group column v-model="Instance.AvaliationTypeId">
                    <v-radio v-for="method in CourseAvaliationTypes" :key="method.TypeId" :label="method.Name" :value="method.TypeId"></v-radio>
                  </v-radio-group>
                  <div v-if="Instance.AvaliationTypeId === 3">
                    <v-label>
                      Escolha o teste que irá definir a classificação do
                      Formação
                    </v-label>
                    <v-select v-model="Instance.IdAvaliationTest" item-text="Name" item-value="IdCourseContent" :items="CourseTests" label="Teste"></v-select>
                  </div>
                  <div v-else>
                    <v-label>Classificação mínima para aprovar o Formação</v-label>
                    <v-text-field solo type="number" v-model="Instance.MinClassification"></v-text-field>
                  </div>
                </v-flex>

                <!-- <v-flex xs6></v-flex> -->

                <!-- <v-layout row wrap>
                  <v-flex xs12>
                    <v-checkbox
                      class="mt-0"
                      label="Inscrições apenas para convidados"
                      :input-value="Instance.OnlyForGuests"
                      v-model="Instance.OnlyForGuests"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>-->
                <v-flex xs6>
                  <v-label>Idioma</v-label>
                  <v-select :items="Languages" item-text="Name" item-value="IdLanguage" v-model="Instance.IdLanguage"></v-select>
                </v-flex>
                <v-flex xs6>
                  <v-label>Grupo (s) de utilizador</v-label>
                  <v-select v-model="Instance.UserGroupsIds" :items="userGroups" item-text="Name" item-value="IdUserGroup" value="IdUserGroup" multiple></v-select>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-btn color="white" @click.prevent="cancel">Cancelar</v-btn>
              <v-btn color="accent" class="ml-auto" type="submit" :isLoading="isLoading" :disabled="!isFormValid">Alterar</v-btn>
            </v-card-actions>
          </v-card>
        </v-tab-item>
        <!-- TAB REPORT -->
        <v-tab-item :value="`tab-report`">
          <v-card>
            <v-card-text class="custom-grid">
              <v-flex xs12 class="mb-2">
                <h2>Anexo C</h2>
              </v-flex>
              <v-layout row wrap>
                <v-flex xs12>
                  <v-label>
                    Situação face à frequência de formação profissional ou
                    equivalente
                  </v-label>
                  <course-info-selector :items="tab28Items" @input="saveToCourseInfos" :table="`Tab-28`" :value="getInfoByCode('Tab-28')" @remove="removeFromInfos"></course-info-selector>
                </v-flex>

                <v-flex xs12>
                  <v-label>Período de referência da formação</v-label>
                  <course-info-selector :items="tab29Items" @input="saveToCourseInfos" :table="`Tab-29`" :value="getInfoByCode('Tab-29')" @remove="removeFromInfos"></course-info-selector>
                </v-flex>

                <v-flex xs12>
                  <v-label>Área de educação/formação da ação</v-label>
                  <course-info-selector :items="tab30Items" @input="saveToCourseInfos" :table="`Tab-30`" :value="getInfoByCode('Tab-30')" @remove="removeFromInfos"></course-info-selector>
                </v-flex>

                <v-flex xs12>
                  <v-label>Modalidade de formação</v-label>
                  <course-info-selector :items="tab31Items" @input="saveToCourseInfos" :table="`Tab-31`" :value="getInfoByCode('Tab-31')" @remove="removeFromInfos"></course-info-selector>
                </v-flex>

                <v-flex xs12>
                  <v-label>Iniciativa da Formação</v-label>
                  <course-info-selector :items="tab32Items" @input="saveToCourseInfos" :table="`Tab-32`" :value="getInfoByCode('Tab-32')" @remove="removeFromInfos"></course-info-selector>
                </v-flex>

                <v-flex xs12>
                  <v-label>Hórario da formação</v-label>
                  <course-info-selector :items="tab33Items" @input="saveToCourseInfos" :table="`Tab-33`" :value="getInfoByCode('Tab-33')" @remove="removeFromInfos"></course-info-selector>
                </v-flex>

                <v-flex xs12>
                  <v-label>Entidade formadora</v-label>
                  <course-info-selector :items="tab34Items" @input="saveToCourseInfos" :value="getInfoByCode('Tab-34')" :table="`Tab-34`" @remove="removeFromInfos"></course-info-selector>
                </v-flex>

                <v-flex xs12>
                  <v-label>Tipo de certificado/diploma</v-label>
                  <course-info-selector :value="getInfoByCode('Tab-35')" :items="tab35Items" @input="saveToCourseInfos" :table="`Tab-35`" @remove="removeFromInfos"></course-info-selector>
                </v-flex>

                <v-flex xs12>
                  <v-label>Nível de qualificação da formação</v-label>
                  <course-info-selector :items="tab36Items" @input="saveToCourseInfos" :table="`Tab-36`" :value="getInfoByCode('Tab-36')" @remove="removeFromInfos"></course-info-selector>
                </v-flex>
                <v-flex class="my-2">
                  <v-divider></v-divider>
                </v-flex>
                <v-flex xs12 class="mb-2">
                  <h2>Anexo D</h2>
                </v-flex>
                <v-flex xs12>
                  <v-label>Classificações SST</v-label>
                  <v-select item-text="Title" :items="Classifications" v-model="Instance.ClassificationsIds" item-value="IdCourseClassification" :clearable="true" multiple></v-select>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-btn color="white" @click.prevent="cancel">Cancelar</v-btn>
              <v-btn color="accent" class="ml-auto" type="submit" :isLoading="isLoading" :disabled="!isFormValid">Alterar</v-btn>
            </v-card-actions>
          </v-card>
        </v-tab-item>
      </v-form>
    </v-tabs-items>
  </div>
</template>

<script>
import router from "@/router/router";
import { required, minLength } from "vuelidate/lib/validators";
import { GetTypeNameById, GetTypeIconById } from "@/models/course-types";
import { FormationDomains } from "@/models/formation-domain";
import {
  GetPlataformTypeNameById,
  GetPlatformIconById,
} from "@/models/course-plataform-types";
import { CourseMethodologyTypes } from "@/models/course-methodology-types";
import { CourseArchitectureTypes } from "@/models/course-architecture-types";
import { CourseAvaliationTypes } from "@/models/course-avaliation-types";
import { CourseLevelTypes } from "@/models/course-levels-types";
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import DatePickerComponent from "@/components/forms/date-picker";
import courseService from "@/services/api/coursesService";
import surveyService from "@/services/api/surveyService";
import SupplierService from "@/services/api/suppliersService";
import courseCategoriesService from "@/services/api/courseCategoriesService";
import courseClassificationsService from "@/services/api/courseClassificationsService";
import courseContentsService from "@/services/api/courseContentsService";
import UploadHelper from "@/utilities/upload";
import UrlBuilder from "@/utilities/url-builder";
import { KendoUI } from "@/settings";
import { Test } from "@/models/course-content-types";
import { CourseRepeatableTypes } from "@/models/course-repeatable-types";
import { Avaliation, Satisfaction, Formation } from "@/models/surveys-types";
import CourseInfosSelectorComponent from "@/components/selectors/course-infos-selector";
import CourseSelectorComponent from "@/components/selectors/courses";
import { CoursePlataformTypes } from "@/models/course-plataform-types";
import { Table_28 } from "@/models/table-28";
import { Table_29 } from "@/models/table-29";
import { Table_30 } from "@/models/table-30";
import { Table_31 } from "@/models/table-31";
import { Table_32 } from "@/models/table-32";
import { Table_33 } from "@/models/table-33";
import { Table_34 } from "@/models/table-34";
import { Table_35 } from "@/models/table-35";
import { Table_36 } from "@/models/table-36";

export default {
  components: {
    "app-date-picker": DatePickerComponent,
    "course-info-selector": CourseInfosSelectorComponent,
    "course-selector": CourseSelectorComponent,
  },
  props: ["course"],
  data () {
    return {
      plataformTypes: CoursePlataformTypes,
      Hours: 0,
      Infos: [],
      tab28Items: Table_28,
      tab29Items: Table_29,
      tab30Items: Table_30,
      tab31Items: Table_31,
      tab32Items: Table_32,
      tab33Items: Table_33,
      tab34Items: Table_34,
      tab35Items: Table_35,
      tab36Items: Table_36,
      Courses: [],
      FormationDomains: FormationDomains,
      isLoading: false,
      CourseTests: [],
      SatisfactionSurveys: [],
      AvaliationSurveys: [],
      FormationSurveys: [],
      SelectedUserGroups: [],
      Instance: {},
      Categories: [],
      Classifications: [],
      SuppliersIds: [],
      suppliers: null,
      currentTab: "tab-details",
      CourseLevels: CourseLevelTypes,
      CourseMethodologyTypes: CourseMethodologyTypes,
      CourseArchitectureTypes: CourseArchitectureTypes,
      CourseAvaliationTypes: CourseAvaliationTypes,
      CourseRepeatableTypes: CourseRepeatableTypes,
      editorTools: KendoUI.BasicRichEditorTools,
    };
  },
  validations: {
    Instance: {
      Name: {
        required,
        minLength: minLength(3),
      },
      Code: {
        required,
        minLength: minLength(3),
      },
    },
  },
  watch: {
    Hours (val) {
      this.Instance.MinutesFormation = parseInt(val * 60);
    },
  },
  computed: {
    ...mapGetters({
      Languages: types.GET_LANGUAGES,
      userGroups: types.GET_USER_GROUPS,
    }),
    courseTypeName () {
      return GetTypeNameById(this.Instance.TypeId);
    },
    courseTypeIcon () {
      return GetTypeIconById(this.Instance.TypeId);
    },
    coursePlataformTypeName () {
      return GetPlataformTypeNameById(this.Instance.PlataformTypeId);
    },
    coursePlataformTypeIcon () {
      return GetPlatformIconById(this.Instance.PlataformTypeId);
    },
    isFormValid () {
      return !this.$v.Instance.$error && this.Instance.Code.length > 2;
    },
  },
  methods: {
    removeChip (item) {
      var index = this.Instance.RelatedCoursesIds.indexOf(item.IdCourse);
      if (index != -1) {
        this.course.RelatedCourses.splice(index, 1);
        this.Instance.RelatedCoursesIds.splice(index, 1);
      }
    },
    nextTab (text) {
      this.currentTab = text;
    },
    async loadCourses () {
      await courseService
        .Get()
        .then((res) => {
          this.Courses = res.data.Items;
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    async loadSuppliers () {
      await SupplierService.Get()
        .then((res) => {
          this.suppliers = res.data.Items;
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    saveToCourseInfos (item) {
      if (item && !this.Instance.CourseInfos.find((s) => s.Code == item.Code))
        this.Instance.CourseInfos.push(item);
      else {
        this.Instance.CourseInfos = this.Instance.CourseInfos.filter(
          (s) => s.Code != item.Code
        );
        this.Instance.CourseInfos.push(item);
      }
    },
    removeFromInfos (table) {
      if (table)
        this.Instance.CourseInfos = this.Instance.CourseInfos.filter(
          (s) => s.Code != table
        );
    },
    async getSatisfactionSurveys () {
      let options = {
        Filters: [
          { Field: "TypeId", Operation: "eq", Value: Satisfaction.TypeId },
        ],
        Order: [],
      };
      await surveyService
        .Get(options)
        .then((response) => {
          this.SatisfactionSurveys = response.data.Items;
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    async getAvaliationSurveys () {
      let options = {
        Filters: [
          { Field: "TypeId", Operation: "eq", Value: Avaliation.TypeId },
        ],
        Order: [],
      };
      await surveyService
        .Get(options)
        .then((response) => {
          this.AvaliationSurveys = response.data.Items;
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    async getFormationSurveys () {
      let options = {
        Filters: [
          { Field: "TypeId", Operation: "eq", Value: Formation.TypeId },
        ],
        Order: [],
      };
      await surveyService
        .Get(options)
        .then((response) => {
          this.FormationSurveys = response.data.Items;
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    GetRelativePath (path) {
      return UrlBuilder.GetPath(path);
    },
    onSelectFile (evt) {
      UploadHelper.LoadFile(evt)
        .then((file) => (this.Instance.Photo = file))
        .catch((file) => (this.Instance.Photo = file));
    },
    async readCategories () {
      await courseCategoriesService
        .Get()
        .then((response) => {
          this.Categories = response.data.Items;
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    async readClassifications () {
      await courseClassificationsService
        .Get()
        .then((response) => {
          this.Classifications = response.data.Items;
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    async updateInstance () {
      if (!this.isLoading) {
        this.isLoading = true;
        await courseService
          .Update(this.Instance, this.Infos, {})
          .then(() => {
            this.isLoading = false;
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Editou a Formação com sucesso!"
            );
            this.$emit("reloadCourse");
            // router.push(`/catalog/courses/${this.Instance.IdCourse}/dashboard`);
          })
          .catch((error) => {
            this.isLoading = false;
            notificationServiceBus.showError(error.response);
          });
      }
    },
    cancel () {
      router.push(`/catalog/courses/${this.Instance.IdCourse}/dashboard`);
    },
    async loadCourseTests () {
      await courseContentsService
        .Get({
          Filters: [
            { Field: "IdCourse", Operation: "eq", Value: this.course.IdCourse },
            { Field: "TypeId", Operation: "eq", Value: Test.TypeId },
          ],
        })
        .then((res) => {
          this.CourseTests = res.data.Items;
        });
    },
    getInfoByCode (code) {
      var item = this.Instance.CourseInfos.find((s) => s.Code === code);
      return item ? item.Value : null;
    },
  },
  async created () {
    this.Instance = { ...this.course };
    this.Hours = this.Instance.MinutesFormation / 60;
    await this.loadCourses();
    await this.loadSuppliers();
    await this.readCategories();
    await this.readClassifications();
    await this.loadCourseTests();
    await this.getSatisfactionSurveys();
    await this.getAvaliationSurveys();
    await this.getFormationSurveys();
    this.SelectedUserGroups = Object.keys(this.Instance.UserGroups).map(
      (i) => this.Instance.UserGroups[i].IdUserGroup
    );
    delete this.Instance.UserGroups;
    delete this.Instance.Categories;
  },
};
</script>

<style scoped></style>
