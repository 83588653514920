export const Formate = {
  TypeId: 1,
  Name: "Interno (RH)",
  Icon: "icon-report-formate",
};
export const Quizz = {
  TypeId: 2,
  Name: "Externo (PS + Famílias)",
  Icon: "icon-quizz",
};

export const CoursePlataformTypes = [Formate, Quizz];

export function GetPlataformTypeNameById(id) {
  for (let index = 0; index < CoursePlataformTypes.length; index++) {
    if (CoursePlataformTypes[index].TypeId === id) {
      return CoursePlataformTypes[index].Name;
    }
  }
  return "";
}
export function GetPlatformIconById(id) {
  for (let index = 0; index < CoursePlataformTypes.length; index++) {
    if (CoursePlataformTypes[index].TypeId === id) {
      return CoursePlataformTypes[index].Icon;
    }
  }
  return "";
}
