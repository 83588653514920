<template>
  <section>
    <app-page-title>
      <v-layout row justify-space-between align-end>
        <v-flex>
          <h1 class="size--s25 white--text mb-4">Listagem de Porfolios</h1>
          <kendo-dropdownlist
            class="mr-2"
            v-model="Category"
            :data-source="Categories"
            :data-text-field="'Name'"
            :data-value-field="'IdPortfolioCategory'"
            :optionLabel="'Categoria...'"
          />
          <v-btn color="grey lighten-2" @click="loadItems()">
            <span class="text-uppercase size--s14 font-weight-light"
              >Filtrar</span
            >
            <i class="icon-filter ml-2" />
          </v-btn>
        </v-flex>
        <v-flex class="text-right">
          <app-form-modal
            ref="modal"
            class="ml-auto"
            class-icon="icon-add"
            title-dialog="Adicionar Portfolio"
            :confirmAction="saveInstance"
            :cancelAction="clearData"
            :formValid="!$v.Instance.$error"
          >
            <div class="custom-grid">
              <v-text-field
                v-model="Instance.Name"
                :error="$v.Instance.Name.$error"
                @blur="$v.Instance.Name.$touch()"
                label="Nome do Portfolio"
                required
              ></v-text-field>
              <v-select
                :items="portfolioTypes"
                label="Tipo"
                item-text="Name"
                item-value="TypeId"
                :error="$v.Instance.TypeId.$error"
                @blur="$v.Instance.TypeId.$touch()"
                v-model="Instance.TypeId"
              ></v-select>
              <v-layout row wrap>
                <v-flex xs6>
                  <app-date-picker
                    v-model="Instance.DateStart"
                    :error="$v.Instance.DateStart.$error"
                    @blur="$v.Instance.DateStart.$touch()"
                    title="Data Início"
                  ></app-date-picker>
                </v-flex>
                <v-flex xs6>
                  <app-date-picker
                    v-model="Instance.DateEnd"
                    :error="$v.Instance.DateEnd.$error"
                    @blur="$v.Instance.DateEnd.$touch()"
                    title="Data Fim"
                  ></app-date-picker>
                </v-flex>
              </v-layout>
            </div>
          </app-form-modal>
        </v-flex>
      </v-layout>
    </app-page-title>
    <v-container>
      <Grid
        :style="{ height: 'auto' }"
        :data-items="Items"
        :columns="Columns"
        :pageable="true"
        :skip="skip"
        :take="take"
        :total="grid.pagging.total"
        @pagechange="pageChange"
        :sortable="true"
        :sort="grid.sort"
        @sortchange="sortChange"
        @remove="remove"
      >
        <template slot="linkCell" slot-scope="{ props }">
          <td :class="props.className">
            <router-link
              :to="`/catalog/portfolios/${props.dataItem.IdPortfolio}`"
              >{{ props.dataItem[props.field] }}</router-link
            >
          </td>
        </template>
        <template slot="categoryCell" slot-scope="{ props }">
          <td :class="props.className">
            {{ props.dataItem.Category ? props.dataItem.Category.Name : "-" }}
          </td>
        </template>
        <template slot="detailCell" slot-scope="{ props }">
          <td :class="props.className">
            <router-link
              class="k-button k-button-icontext"
              :to="`/catalog/portfolios/${props.dataItem.IdPortfolio}/edit`"
            >
              <span class="icon-mode_edit"></span>
            </router-link>
          </td>
        </template>
        <GridNoRecords>Não existem portfólios</GridNoRecords>
      </Grid>
    </v-container>
  </section>
</template>

<script>
import * as kendo from "@progress/kendo-ui";
import { DateTimeFormat } from "@/settings";
import { PortfolioTypes } from "@/models/portfolio-types";
import { required, minLength } from "vuelidate/lib/validators";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import FormModalComponent from "@/components/modals/form-modal";
import DatePickerComponent from "@/components/forms/date-picker";
import portfoliosService from "@/services/api/portfoliosService";
import portfolioCategoriesService from "@/services/api/portfolioCategoriesService";
import router from "@/router/router";
import GridHelper from "@/utilities/grid";
import StringFilterCell from "@/components/grid/string-filter.js";
import DateFilterCell from "@/components/grid/date-filter.js";
import DeleteCommandCell from "@/components/grid/delete-command.js";

export default {
  components: {
    "app-form-modal": FormModalComponent,
    "app-date-picker": DatePickerComponent,
  },
  data() {
    return {
      portfolioTypes: PortfolioTypes,
      dateTimeFormat: `{0:${DateTimeFormat}}`,
      Categories: null,
      Category: null,
      Instance: { IdPortfolio: 0, Code: "", Name: "" },
      grid: {
        sort: [],
        pagging: {
          pageId: 1,
          pageSize: 10,
          total: 0,
        },
      },
      Items: [],
      Columns: [
        {
          field: "Name",
          title: "Nome",
          filterCell: StringFilterCell,
          cell: "linkCell",
        },
        {
          field: "Type",
          title: "Tipo",
          headerCell: this.noSortableFunction,
        },
        {
          field: "IdPortfolioCategory",
          title: "Categoria",
          headerCell: this.noSortableFunction,
          cell: "categoryCell",
        },
        {
          width: 225,
          field: "DateStart",
          title: "Data início",
          format: this.dateTimeFormat,
          filterCell: DateFilterCell,
        },
        {
          width: 225,
          field: "DateEnd",
          title: "Data fim",
          format: this.dateTimeFormat,
          filterCell: DateFilterCell,
        },
        {
          headerCell: this.noSortableFunction,
          cell: "detailCell",
          width: 75,
          title: "",
        },
        {
          headerCell: this.noSortableFunction,
          cell: DeleteCommandCell,
          width: 75,
          title: "",
        },
      ],
    };
  },
  validations: {
    Instance: {
      Name: {
        required,
        minLength: minLength(3),
      },
      DateStart: { required },
      DateEnd: { required },
      TypeId: { required },
    },
  },
  computed: {
    isNew() {
      return this.Instance != null && this.Instance.IdPortfolio == 0;
    },
    skip() {
      return (this.grid.pagging.pageId - 1) * this.grid.pagging.pageSize;
    },
    take() {
      return this.grid.pagging.pageSize;
    },
  },
  watch: {
    $route() {
      if (location.hash) {
        this.$refs.modal.open();
      }
    },
  },
  methods: {
    noSortableFunction: function (h, tdElement, props) {
      return h("span", [props.title]);
    },
    clearData(callBack) {
      this.resetInstance();
      callBack(true);
    },
    sortChange(ev) {
      this.grid.sort = ev.sort;
      this.grid.pagging.pageId = 1;
      this.loadItems();
    },
    pageChange(ev) {
      this.grid.pagging.pageId = ev.page.skip / ev.page.take + 1;
      this.loadItems();
    },
    remove(item) {
      this.deleteInstance(item.dataItem);
    },
    async loadItems() {
      let options = GridHelper.ConvertOptionsFromNativeKendoUIGrid(
        this.grid.pagging,
        null,
        this.grid.sort
      );
      if (this.Category) {
        options.Filters.push({
          Field: "IdPortfolioCategory",
          Operation: "eq",
          Value: parseInt(this.Category),
        });
      }
      await portfoliosService
        .Get(options)
        .then((res) => {
          this.Items = res.data.Items;
          this.grid.pagging.total = res.data.Total;
          this.resetInstance();
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    resetInstance() {
      this.Instance = { IdCourse: 0, Code: "", Name: "", TypeId: 1 };
    },
    async getCategories() {
      await portfolioCategoriesService
        .Get()
        .then((res) => {
          this.Categories = res.data.Items;
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    async saveInstance(callBack) {
      await portfoliosService
        .Create(this.Instance)
        .then((response) => {
          notificationServiceBus.showSuccessMessage(
            "Successo!",
            "Criou o portfolio com sucesso!"
          );
          this.resetInstance();
          callBack(true);
          router.push(
            `/catalog/portfolios/${response.data.Instance.IdPortfolio}/`
          );
        })
        .catch((error) => {
          callBack(false);
          notificationServiceBus.showError(error.response);
        });
    },
    deleteInstance(item) {
      var id = item.IdPortfolio;
      kendo.confirm("Tem a certeza que quer apagar o portfolio?").done(() => {
        portfoliosService
          .Delete(id)
          .then(() => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Apagou o portfolio com sucesso!"
            );
            this.loadItems();
          })
          .catch((error) => {
            notificationServiceBus.showError(error.response);
          });
      });
    },
  },
  async created() {
    this.resetInstance();
    this.$v.$touch();
    await this.loadItems();
    await this.getCategories();
  },
  mounted() {
    if (location.hash) {
      this.$refs.modal.open();
    }
  },
};
</script>

<style></style>
