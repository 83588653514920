export const Table_33 = [
  {
    Code: "01",
    Text: "Laboral",
    Hint: "Se a ação de formação decorreu durante o horário normal de trabalho.",
  },
  {
    Code: "02",
    Text: "Pós-laboral",
    Hint: "Se a ação de formação decorreu fora do horário normal de trabalho.",
  },
  {
    Code: "03",
    Text: "Misto",
    Hint: "Se parte da ação de formação decorreu durante o horário normal de trabalho e outra parte decorreu fora do horário normal de trabalho.",
  },
];

export function GetTypeTextById(id) {
  for (let index = 0; index < Table_33.length; index++) {
    if (Table_33[index].Code === id) {
      return Table_33[index].Text;
    }
  }
  return "";
}
