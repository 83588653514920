<template>
  <section id="CoursesIndex">
    <app-page-title>
      <v-layout row justify-space-between align-end>
        <v-flex>
          <h1>Listagem de Formações</h1>
          <app-selector-coursetypes class="mr-2" v-model="filters.TypeId"></app-selector-coursetypes>
          <app-selector-courseplataformtypes class="mr-2" v-model="filters.PlataformTypeId"></app-selector-courseplataformtypes>
          <span class="custom-checkbox mr-2">
            <v-checkbox v-model="filters.IsActive" label="Activo"></v-checkbox>
          </span>
          <v-btn color="grey lighten-2 mr-2" @click="loadItems()">
            <span class="text-uppercase size--s14 font-weight-light">Filtrar</span>
            <i class="icon-filter ml-2" />
          </v-btn>
        </v-flex>
        <v-flex class="text-right">
          <app-form-modal ref="modal" class-icon="icon-add" title-dialog="Adicionar Formação" :confirmAction="saveInstance" :cancelAction="clearData" :formValid="!$v.Instance.$error && Instance.Code.length > 3" @opened="loadNextCode()">
            <v-text-field v-model="Instance.Code" :error="$v.Instance.Code.$error" @blur="$v.Instance.Code.$touch()" label="Código" required></v-text-field>
            <v-text-field v-model="Instance.Name" :error="$v.Instance.Name.$error" @blur="$v.Instance.Name.$touch()" label="Nome do Formação" required></v-text-field>
            <div class="custom-grid">
              <v-layout row>
                <v-flex xs6>
                  <v-select v-model="Instance.TypeId" :items="types" label="Tipo de Formação" item-text="Name" item-value="TypeId" required></v-select>
                </v-flex>
                <v-flex xs6>
                  <v-select v-model="Instance.PlataformTypeId" :items="plataformTypes" label="Plataforma de Formação" item-text="Name" item-value="TypeId" required></v-select>
                </v-flex>
              </v-layout>
            </div>
          </app-form-modal>
        </v-flex>
      </v-layout>
    </app-page-title>
    <v-container>
      <Grid :style="{ height: 'auto' }" :data-items="Items" :columns="Columns" :pageable="true" :skip="skip" :take="take" :total="grid.pagging.total" @pagechange="pageChange" :sortable="true" :sort="grid.sort" @sortchange="sortChange">
        <template slot="noSortCell" slot-scope="{ props }">
          {{ props.title }}
        </template>
        <template slot="typeIconCell" slot-scope="{ props }">
          <td :class="props.className">
            <i :class="typeColumnIconTemplate(props.dataItem)" class="size--s20"></i>
          </td>
        </template>
        <template slot="categoriesCell" slot-scope="{ props }">
          <td :class="props.className">
            <v-chip v-for="category in props.dataItem.Categories" :key="category.IdCourseCategory" color="secondary" text-color="white" class="mr-2">{{ category.Title }}</v-chip>
          </td>
        </template>
        <template slot="linkCell" slot-scope="{ props }">
          <td :class="props.className">
            <router-link :to="`/catalog/courses/${props.dataItem.IdCourse}`">
              {{ props.dataItem[props.field] }}
            </router-link>
          </td>
        </template>
        <template slot="hasContentCell" slot-scope="{ props }">
          <td :class="props.className">
            <div v-if="props.dataItem.ModulesIds && props.dataItem.ModulesIds.length > 0">
              <span class="label success">Sim</span>
            </div>
            <div v-else>
              <span class="label danger">Não</span>
            </div>
          </td>
        </template>
        <template slot="detailCell" slot-scope="{ props }">
          <td :class="props.className">
            <router-link class="k-button k-button-icontext" :to="`/catalog/courses/${props.dataItem.IdCourse}/edit`">
              <span class="icon-mode_edit"></span>
            </router-link>
          </td>
        </template>
        <template slot="TypeCell" slot-scope="{ props }">
          <td :class="props.className">
            {{ typeColumnNameTemplate(props.dataItem) }}
          </td>
        </template>
        <GridNoRecords>Não existem Formações</GridNoRecords>
      </Grid>
    </v-container>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import router from "@/router/router";
import { required, minLength } from "vuelidate/lib/validators";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import { CourseTypes, Elearn } from "@/models/course-types";
import { CoursePlataformTypes, Formate } from "@/models/course-plataform-types";
import FormModalComponent from "@/components/modals/form-modal";
import CourseTypesSelectorComponent from "@/components/selectors/course-types";
import CoursePlataformTypesSelectorComponent from "@/components/selectors/course-plataform-types";
import coursesService from "@/services/api/coursesService";
import GridHelper from "@/utilities/grid";

export default {
  components: {
    "app-form-modal": FormModalComponent,
    "app-selector-coursetypes": CourseTypesSelectorComponent,
    "app-selector-courseplataformtypes": CoursePlataformTypesSelectorComponent,
  },
  data () {
    return {
      Instance: {
        IdCourse: 0,
        Name: "",
        Code: "",
        TypeId: Elearn.TypeId,
        PlataformTypeId: Formate.TypeId,
      },
      types: CourseTypes,
      plataformTypes: CoursePlataformTypes,
      filters: {
        TypeId: null,
        PlataformTypeId: null,
        IsActive: true,
      },
      grid: {
        sort: [{ field: "Code", dir: "asc" }],
        pagging: {
          pageId: 1,
          pageSize: 20,
          total: 0,
        },
      },
      Items: [],
      Columns: [
        {
          title: "",
          cell: "typeIconCell",
          width: 80,
          headerCell: "noSortCell",
        },
        {
          field: "Code",
          title: "Código",
          width: 200,
          cell: "linkCell",
        },
        {
          field: "TypeId",
          title: "Tipo",
          cell: "TypeCell",
          headerCell: "noSortCell",
        },
        {
          field: "Name",
          title: "Nome",
          cell: "linkCell",
        },
        {
          field: "Content",
          title: "Conteúdo?",
          width: 85,
          cell: "hasContentCell",
        },
        {
          field: "CategoriesIds",
          title: "Categorias",
          cell: "categoriesCell",
          headerCell: "noSortCell",
        },
        {
          cell: "detailCell",
          width: 75,
          title: "",
          headerCell: "noSortCell",
        },
      ],
    };
  },
  validations: {
    Instance: {
      Name: {
        required,
        minLength: minLength(3),
      },
      Code: {
        required,
        minLength: minLength(3),
      },
    },
  },
  watch: {
    $route () {
      if (location.hash) {
        this.$refs.modal.open();
      }
    },
    "Instance.PlataformTypeId" () {
      this.loadNextCode();
    },
  },
  computed: {
    ...mapGetters({
      userGroups: types.GET_USER_GROUPS,
    }),
    isNew () {
      return this.Instance != null && this.Instance.IdCourse == 0;
    },
    skip () {
      return (this.grid.pagging.pageId - 1) * this.grid.pagging.pageSize;
    },
    take () {
      return this.grid.pagging.pageSize;
    },
  },
  methods: {
    clearData (callBack) {
      this.resetInstance();
      callBack(true);
    },
    sortChange (ev) {
      this.grid.sort = ev.sort;
      this.grid.pagging.pageId = 1;
      this.loadItems();
    },
    pageChange (ev) {
      this.grid.pagging.pageId = ev.page.skip / ev.page.take + 1;
      this.loadItems();
    },
    async loadItems () {
      let options = GridHelper.ConvertOptionsFromNativeKendoUIGrid(
        this.grid.pagging,
        null,
        this.grid.sort
      );
      if (this.filters.TypeId) {
        options.Filters.push({
          Field: "TypeId",
          Operation: "eq",
          Value: this.filters.TypeId,
        });
      }
      if (this.filters.PlataformTypeId) {
        options.Filters.push({
          Field: "PlataformTypeId",
          Operation: "eq",
          Value: this.filters.PlataformTypeId,
        });
      }
      if (this.filters.IsActive) {
        options.Filters.push({
          Field: "IsActive",
          Operation: "eq",
          Value: this.filters.IsActive,
        });
      }
      await coursesService
        .Get(options)
        .then((res) => {
          this.Items = res.data.Items;

          this.grid.pagging.total = res.data.Total;
          this.resetInstance();
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    resetInstance () {
      this.Instance = {
        IdCourse: 0,
        Name: "",
        Code: "",
        TypeId: Elearn.TypeId,
        PlataformTypeId: Formate.TypeId,
      };
    },
    async saveInstance (callBack) {
      if (this.isNew) {
        await coursesService
          .Create(this.Instance)
          .then((res) => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Criou o Formação com sucesso!"
            );
            callBack(true);
            router.push(`/catalog/courses/${res.data.Instance.IdCourse}/edit`);
          })
          .catch((error) => {
            callBack(false);
            notificationServiceBus.showError(error.response);
          });
      }
    },
    async loadNextCode () {
      await coursesService
        .GetNextCode(this.Instance.PlataformTypeId)
        .then((res) => {
          this.Instance.Code = res.data;
        })
        .catch(() => {
          this.Instance.Code = "";
        });
    },
    groupColumnTemplate (id) {
      const item = this.userGroups.find((s) => s.IdUserGroup === id);
      return item ? item.Name : "";
    },
    typeColumnNameTemplate (e) {
      const type = this.types.find((s) => s.TypeId === e.TypeId);
      return type ? type.Name : "";
    },
    typeColumnIconTemplate (e) {
      const type = this.plataformTypes.find(
        (s) => s.TypeId === e.PlataformTypeId
      );
      return type ? type.Icon : "";
    },
  },
  async created () {
    await this.loadItems();
  },
  mounted () {
    if (location.hash) {
      this.$refs.modal.open();
    }
  },
};
</script>
<style lang="scss" scoped>
.k-list-optionlabel,
.k-list li {
  font-size: 14px !important;
}

.label {
  padding: 2px 3px;
  border-radius: 4px;
  color: white;
  font-size: 10px;

  &.success {
    background-color: #55a500;
  }

  &.danger {
    background-color: #B71C1C;
  }
}
</style>
