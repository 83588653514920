export const Table_31 = [
  {
    Code: "01",
    Text: "Cursos profissionais",
    Hint: "Entendendo-se como tais os cursos de nível secundário de educação, vocacionados para a formação inicial de jovens, privilegiado a sua inserção na vida activa e permitindo o prosseguimento de estudos. Conferem um nível de qualificação.",
  },
  {
    Code: "02",
    Text: "Cursos de aprendizagem",
    Hint: "Entendendo-se como tais os cursos de formação profissional inicial de jovens. Em alternância, privilegiando a sua inserção na vida ativa permitindo o prosseguimento de estudos. Conferem um nível de qualificação.",
  },
  {
    Code: "03",
    Text: "Cursos de educação e formação para jovens",
    Hint: `Entendendo-se como tais os cursos de formação profissional inicial para jovens que abandonaram ou estão em risco de abandonar o sistema regular de ensino, 
      privilegiando a sua inserção na vida activa e permitindo o prosseguimento de estudos. Conferem um nível de qualificação.`,
  },
  {
    Code: "04",
    Text: "Cursos de educação e formação para adultos",
    Hint: `Entendendo-se como tais os cursos que se destinam a indivíduos com idade igual ou superior a 18 anos, não qualificados ou sem qualificação adequada, 
      para efeitos de inserção, reinserção e progressão no mercado de trabalho e que não tenham concluído o ensino básico ou o secundário. 
      Conferem um nível de qualificação.`,
  },
  {
    Code: "05",
    Text: "Cursos de especialização tecnológica",
    Hint: "Entendendo-se como tais os cursos de nível pós-secundário não superior que visam conferir uma qualificação com base em formação técnica especializada. Conferem um nível de qualificação.",
  },
  {
    Code: "06",
    Text: "Outras formações modulares inseridas no Catálogo Nacional de Qualificações, no quadro da formação continua",
    Hint: "Conferem um nível de qualificação.\nConsulte: http://www.catalogo.anq.gov.pt/ConsultaCatalogo/Qualificacoes/\nPaginas/Qualificacoes.aspx",
  },
  {
    Code: "07",
    Text: "Formação – Ação",
    Hint: `Dirigida a micro, pequenas e médias empresas (Artº 100 da Lei nº 7/2009, de 12 de Fevereiro) e assente na prestação de serviços 
      integrados de formação e consultadoria, regulada por portaria do membro do governo responsável pela área da formação profissional.`,
  },
  {
    Code: "08",
    Text: "Outras ações de formação contínua não inseridas no Catálogo Nacional de Qualificações",
    Hint: "Nomeadamente as realizadas por empresas e inseridas no processo de inovação, modernização e reconversão empresarial.",
  },
  {
    Code: "99",
    Text: "Desconhecida",
    Hint: "",
  },
];

export function GetTypeTextById(id) {
  for (let index = 0; index < Table_31.length; index++) {
    if (Table_31[index].Code === id) {
      return Table_31[index].Text;
    }
  }
  return "";
}
