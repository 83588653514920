import axios from "axios";
import { API_URL } from "@/settings";
import * as types from "@/store/types";

const baseUrl = API_URL + "api/surveygroups";

const endPoints = {
  Get: baseUrl + "/fetch",
  Update: baseUrl + "/update",
  Create: baseUrl + "/create",
  Delete: baseUrl + "/delete",
  Reorder: baseUrl + "/reorder",
};

const instance = axios.create();

// eslint-disable-next-line prettier/prettier
instance.defaults.headers.common["Authorization"] = `Bearer ${window.localStorage.getItem(types.GLOBAL_USER_TOKEN)}`
instance.defaults.headers.common["Content-Type"] = "application/json";

const actions = {
  Get(options) {
    return instance.post(endPoints.Get, {
      ...{
        Filters: [],
      },
      ...options,
    });
  },
  GetSingle(id, options) {
    return instance.get(endPoints.Get + "/" + id, {
      params: options,
    });
  },
  Delete(id, options) {
    const data = {
      ...options,
    };
    return instance.delete(endPoints.Delete + "/" + id, {
      data,
    });
  },
  UpdateOrder(IdSurvey, Groups, options = {}) {
    const data = {
      Groups,
      ...options,
    };
    return instance.put(
      `${endPoints.Reorder}/${IdSurvey !== null ? IdSurvey : ""}`,
      data
    );
  },
  Update(item, options = {}) {
    const data = {
      Instance: item,
      ...options,
    };
    return instance.put(endPoints.Update, data);
  },
  Create(item, options = {}) {
    const data = {
      Instance: item,
      ...options,
    };
    return instance.post(endPoints.Create, data);
  },
};

export default actions;
