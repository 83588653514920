var T010 = {
  TypeId: 1,
  Name: "010",
};
var T072 = {
  TypeId: 2,
  Name: "072",
};

export const PortfolioRestrictionTypes = [T010, T072];
