<template>
  <base-uploader
    :async="async"
    :type="type"
    :validations="validations"
    @onSuccess="onSuccess"
    @onError="onError"
    @onProgress="onProgress"
  >
  </base-uploader>
</template>

<script>
import BaseUploaderComponent from "./base-uploader";

export default {
  components: {
    "base-uploader": BaseUploaderComponent,
  },
  props: {
    async: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      type: "audio",
      validations: [".mp3"],
    };
  },
  methods: {
    onError(err) {
      this.$emit("onError", err);
    },
    onProgress() {
      this.$emit("onProgress", true);
    },
    onSuccess(file) {
      this.$emit("onSuccess", file);
    },
  },
};
</script>
