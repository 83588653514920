<template>
  <section v-if="portfolio" id="PortfoliosDetail">
    <app-page-title>
      <div>
        <h1 class="size--s25 white--text">{{ portfolio.Name }}</h1>
      </div>
      <div v-show="!isEditView" class="text-xs-right">
        <v-btn color="info" :to="'edit'">
          Editar
          <i class="icon-arrow-right ml-2"></i>
        </v-btn>
      </div>
    </app-page-title>
    <div class="d-flex align-content-stretch detail-wrapper">
      <div class="detail-sidebar">
        <app-sidebar-menu :menus="menus" />
      </div>
      <div class="detail-content" v-if="Courses && portfolio">
        <router-view
          params="route: route"
          @portfolioEdited="LoadPortfolio"
          :portfolio="portfolio"
          :courses="Courses"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import portfoliosService from "@/services/api/portfoliosService";
import coursesService from "@/services/api/coursesService";
import SidebarComponent from "@/components/layout/page-menu";

export default {
  components: {
    "app-sidebar-menu": SidebarComponent,
  },
  data() {
    return {
      Courses: null,
      portfolio: null,
      menus: [
        {
          Name: "Dashboard",
          Icon: "icon-home",
          Path: "dashboard",
        },
        {
          Name: "Integração",
          Icon: "icon-flag",
          Path: "basic",
        },
        {
          Name: "1 a 3 meses",
          Icon: "icon-flag",
          Path: "short",
        },
        {
          Name: "3 a 6 meses",
          Icon: "icon-flag",
          Path: "medium",
        },
        {
          Name: "Contínua (6+ meses)",
          Icon: "icon-flag",
          Path: "long",
        },
        {
          Name: "Complementar",
          Icon: "icon-flag",
          Path: "complementary",
        },
        {
          Name: "Utilizadores",
          Icon: "icon-users",
          Path: "users",
        },
      ],
    };
  },
  computed: {
    isEditView() {
      return this.$route.path.indexOf("/edit") >= 0;
    },
  },
  watch: {
    "$route.params.idPortfolio"() {
      this.LoadPortfolio();
    },
  },
  async created() {
    await this.LoadPortfolio();
    await this.LoadCourses();
  },
  methods: {
    async LoadCourses() {
      await coursesService
        .Get()
        .then((res) => {
          this.Courses = res.data.Items;
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    async LoadPortfolio() {
      var id = this.$route.params.idPortfolio;
      await portfoliosService
        .GetSingle(id, { Detailed: true })
        .then((response) => {
          this.portfolio = response.data.Instance;
        })
        .catch(() => {
          notificationServiceBus.showErrorMessage(
            "Erro!",
            "ocorreu um erro inesperado! Tente novamente."
          );
        });
    },
  },
};
</script>

<style scoped></style>
