<template>
  <v-container grid-list v-if="survey">
    <div class="k-grid k-grid-toolbar">
      <app-form-modal
        class-icon="icon-add"
        titleButton="Adicionar Grupo"
        :Name-dialog="`${isNew ? 'Adicionar' : 'Editar'} Grupo`"
        :confirmAction="saveInstance"
        :cancelAction="clearData"
        :formValid="!$v.Instance.$error"
        ref="modal"
      >
        <v-layout wrap>
          <v-flex xs12 pr-2>
            <v-text-field
              required
              v-model="Instance.Name"
              label="Nome"
              :error="$v.Instance.Name.$error"
              @blur="$v.Instance.Name.$touch()"
            >
            </v-text-field>
          </v-flex>
          <v-flex xs6 pr-2>
            <v-text-field
              required
              type="number"
              v-model="Instance.OrderId"
              label="Ordem"
              :error="$v.Instance.OrderId.$error"
              @blur="$v.Instance.OrderId.$touch()"
            >
            </v-text-field>
          </v-flex>
        </v-layout>
      </app-form-modal>
    </div>
    <Grid
      :id="gridIdentifier"
      :style="{ height: 'auto' }"
      :data-items="Items"
      :columns="Columns"
      :pageable="true"
      :detail="cellTemplate"
      @expandchange="expandChange"
      :expand-field="'expanded'"
      :total="grid.pagging.total"
      @pagechange="pageChange"
      @edit="edit"
      @remove="remove"
    >
      <GridNoRecords>Não existem resultados</GridNoRecords>
    </Grid>
  </v-container>
</template>
<script>
import Sortable from "sortablejs";
import Vue from "vue";
import * as kendo from "@progress/kendo-ui";
import { required, minLength } from "vuelidate/lib/validators";
import FormModalComponent from "@/components/modals/form-modal";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import EditCommandCell from "@/components/grid/edit-command.js";
import OrderCommandCell from "@/components/grid/order-command.js";
import DeleteCommandCell from "@/components/grid/delete-command.js";
import GridHelper from "@/utilities/grid";
import surveyGroupsService from "@/services/api/surveyGroupsService";

import SurveyQuestionsGridComponent from "./grid";
Vue.component("app-surveys-questions-grid", SurveyQuestionsGridComponent);
const componentInstance = Vue.component(
  "app-surveys-questions-grid-component",
  {
    props: ["dataItem"],
    template: `<app-surveys-questions-grid :dataItem="dataItem"></app-surveys-questions-grid>`,
  }
);

export default {
  components: {
    "app-form-modal": FormModalComponent,
  },
  props: { survey: { type: Object, default: null } },
  data() {
    return {
      Instance: { Name: "" },
      cellTemplate: componentInstance,
      Items: [],
      grid: {
        pagging: {
          pageId: 1,
          pageSize: 10,
          total: 0,
        },
      },
      Columns: [
        {
          cell: OrderCommandCell,
          filterable: false,
          sortable: false,
          width: 75,
          title: "Ordem",
        },
        {
          field: "Name",
          title: "Nome",
        },
        {
          cell: EditCommandCell,
          width: 75,
          Name: "",
        },
        {
          cell: DeleteCommandCell,
          width: 75,
          Name: "",
        },
      ],
    };
  },
  validations: {
    Instance: {
      Name: { required, minLength: minLength(3) },
      OrderId: { required },
    },
  },
  computed: {
    isNew() {
      return this.Instance != null && this.Instance.IdSurveyGroup == 0;
    },
    gridIdentifier() {
      return `GridSurveyGroup`;
    },
  },
  async created() {
    this.resetInstance();
    await this.loadItems();
  },
  mounted() {
    var that = this;
    var element = `${this.gridIdentifier}-Table`;
    this.$el
      .querySelector(
        `#${this.gridIdentifier} > .k-grid-container > .k-grid-content > div > .k-grid-table > tbody`
      )
      .setAttribute("id", element);
    Sortable.create(document.getElementById(element), {
      handle: ".k-action-move",
      animation: 150,
      onEnd: function (evt) {
        var arr = [...that.Items];
        var old = arr[evt.oldIndex];
        arr = arr
          .slice(0, evt.oldIndex)
          .concat(arr.slice(evt.oldIndex + 1, arr.length));
        arr.splice(evt.newIndex, 0, old);
        that.Items = [...[]];
        that.orderItems(arr.map((s) => s.IdSurveyGroup));
      },
    });
  },
  methods: {
    openModal() {
      this.$refs.modal.open();
    },
    expandChange(ev) {
      Vue.set(ev.dataItem, ev.target.$props.expandField, ev.value);
    },
    clearData(callback) {
      this.resetInstance();
      callback(true);
    },
    edit(item) {
      this.Instance = { ...item.dataItem };
      this.openModal();
    },
    remove(item) {
      this.deleteInstance(item.dataItem);
    },
    pageChange(ev) {
      this.grid.pagging.pageId = ev.page.skip / ev.page.take + 1;
      this.readsurveyLogs();
    },
    async loadItems() {
      let options = GridHelper.ConvertOptionsFromNativeKendoUIGrid(
        this.grid.pagging
      );
      let data = GridHelper.ParseOptions(options);
      data.Filters = [
        { Field: "IdSurvey", Operation: "eq", Value: this.survey.IdSurvey },
      ];
      data.Order = [{ Field: "OrderId", Direction: "ASC" }];
      await surveyGroupsService
        .Get(data)
        .then((res) => {
          this.Items = res.data.Items;
          this.grid.pagging.total = res.data.Total;
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    resetInstance() {
      this.Instance = {
        IdSurveyGroup: 0,
        OrderId: 1,
        IdSurvey: this.survey.IdSurvey,
      };
    },
    async saveInstance(callBack) {
      if (this.isNew) {
        await surveyGroupsService
          .Create(this.Instance)
          .then(() => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Adicionou o grupo com sucesso!"
            );
            this.loadItems();
            this.resetInstance();
            callBack(true);
          })
          .catch((error) => {
            callBack(false);
            notificationServiceBus.showError(error.response);
          });
      } else {
        await surveyGroupsService
          .Update(this.Instance)
          .then((response) => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Editou o grupo com sucesso!"
            );
            const index = this.Items.findIndex(
              (s) => s.IdSurveyGroup == this.Instance.IdSurveyGroup
            );
            if (index >= 0) {
              this.Items[index] = { ...response.data.Instance };
            }
            this.resetInstance();
            callBack(true);
          })
          .catch((error) => {
            callBack(false);
            notificationServiceBus.showError(error.response);
          });
      }
    },
    deleteInstance(item) {
      var id = item.IdSurveyGroup;
      kendo.confirm("Tem a certeza que quer apagar o grupo?").done(() => {
        surveyGroupsService
          .Delete(id)
          .then(() => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Apagou o grupo com sucesso!"
            );
            this.loadItems();
          })
          .catch((error) => {
            notificationServiceBus.showError(error.response);
          });
      });
    },
    orderItems(items) {
      surveyGroupsService
        .UpdateOrder(this.survey.IdSurvey, items)
        .then(() => {
          notificationServiceBus.showSuccessMessage(
            "Successo!",
            "alterou a ordem com sucesso!"
          );
          this.loadItems();
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
  },
};
</script>

<style scoped></style>
