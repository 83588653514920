<template>
  <div>
    <v-dialog v-model="isModalContentOpen" persistent max-width="1024px">
      <v-form @submit.prevent="onSaveContent">
        <v-card>
          <v-card-title>
            <span class="headline">Documento de anexo</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="Instance.Name"
              label="Nome"
              :error="$v.Instance.Name.$error"
              @blur="$v.Instance.Name.$touch()"
            ></v-text-field>
            <v-label>Ficheiro</v-label>
            <app-file-uploader
              :async="true"
              @onProgress="isLoading = true"
              @onError="
                isLoading = false;
                Instance.File = null;
              "
              @onSuccess="onFileChange"
            ></app-file-uploader>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="text--left mr-auto ml-0"
              type="button"
              @click.prevent="resetInstance"
              >Cancelar</v-btn
            >
            <v-btn
              color="accent"
              class="text--left"
              type="submit"
              :disabled="$v.Instance.$error"
              :loading="isLoading"
              >Guardar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <div class="k-grid k-grid-toolbar">
      <v-btn color="accent" title="Adicionar" @click.prevent="addContentModal"
        >Adicionar Ficheiro</v-btn
      >
    </div>
    <Grid
      :id="gridIdentifier"
      :style="{ height: 'auto' }"
      :data-items="Items"
      :columns="Columns"
      @edit="edit"
      @remove="remove"
    >
      <template slot="downloadCell" slot-scope="{ props }">
        <td :class="props.className">
          <a :href="getRelativePath(props.dataItem)" target="_blank"
            >Download</a
          >
        </td>
      </template>
      <template slot="FileCell" slot-scope="{ props }">
        <td :class="props.className">{{ getFileNameSrc(props.dataItem) }}</td>
      </template>

      <GridNoRecords>Não existem conteúdos neste módulo</GridNoRecords>
    </Grid>
  </div>
</template>

<script>
import Sortable from "sortablejs";
import * as kendo from "@progress/kendo-ui";
import { required, minLength } from "vuelidate/lib/validators";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import courseModulesDocumentsService from "@/services/api/courseModulesDocumentsService";
import FileAsyncUploaderComponent from "@/components/forms/uploaders/file";

import EditCommandCell from "@/components/grid/edit-command.js";
import DeleteCommandCell from "@/components/grid/delete-command.js";
import OrderCommandCell from "@/components/grid/order-command.js";
import UrlBuider from "@/utilities/url-builder";

export default {
  components: {
    "app-file-uploader": FileAsyncUploaderComponent,
  },
  props: ["moduleId"],
  validations: {
    Instance: {
      Name: {
        required,
        minLength: minLength(4),
      },
    },
  },
  data() {
    return {
      Instance: {},
      isModalContentOpen: false,
      isLoading: false,
      Items: [],
      Columns: [
        {
          cell: OrderCommandCell,
          filterable: false,
          sortable: false,
          width: 75,
          title: "Ordem",
        },
        { field: "Name", title: "Nome do ficheiro" },
        { cell: "FileCell", title: "Nome do Ficheiro" },
        {
          field: "FilenameSrc",
          title: "Ficheiro",
          cell: "downloadCell",
        },
        {
          cell: EditCommandCell,
          width: 75,
          title: "",
        },
        {
          cell: DeleteCommandCell,
          width: 75,
          title: "",
        },
      ],
    };
  },
  computed: {
    isNew() {
      return this.Instance != null && this.Instance.IdCourseModuleDocument == 0;
    },
    gridIdentifier() {
      return `GridModuleDocument${this.moduleId}`;
    },
  },
  async created() {
    this.resetInstance();
    await courseModulesDocumentsService
      .Get({
        Filters: [
          {
            Field: "IdCourseModule",
            Operator: "eq",
            Value: this.moduleId,
          },
        ],
        Order: [{ Field: "OrderId", Direction: "ASC" }],
      })
      .then((response) => {
        this.Items = response.data.Items;
      });
  },
  methods: {
    onFileChange(file) {
      this.Instance.File = file;
      this.isLoading = false;
    },
    getRelativePath(item) {
      return UrlBuider.GetPath(item.FilenameSrc);
    },
    openContentModal() {
      this.isModalContentOpen = true;
    },
    closeContentModal() {
      this.isModalContentOpen = false;
    },
    addContentModal() {
      this.resetInstance();
      this.openContentModal();
    },
    getFileNameSrc(item) {
      const file = item.FilenameSrc
        ? item.FilenameSrc.substring(item.FilenameSrc.lastIndexOf("/"))
        : null;
      return file ? file.substring(1) : "-";
    },
    edit(item) {
      this.Instance = { ...item.dataItem };
      this.openContentModal();
    },
    remove(item) {
      this.deleteInstance(item.dataItem);
    },
    async onSaveContent() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      if (this.isNew) {
        courseModulesDocumentsService
          .Create(this.Instance)
          .then((response) => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Adicionou o ficheiro com sucesso!"
            );
            this.isLoading = false;
            this.Items.push(response.data.Instance);
            this.closeContentModal();
            this.resetInstance();
          })
          .catch((error) => {
            this.isLoading = false;
            notificationServiceBus.showError(error.response);
          });
      } else {
        await courseModulesDocumentsService
          .Update(this.Instance)
          .then((response) => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Editou o ficheiro com sucesso!"
            );
            this.isLoading = false;
            const index = this.Items.findIndex(
              (s) =>
                s.IdCourseModuleDocument == this.Instance.IdCourseModuleDocument
            );
            if (index >= 0) {
              this.Items[index] = { ...response.data.Instance };
            }
            this.closeContentModal();
            this.resetInstance();
          })
          .catch((error) => {
            this.isLoading = false;
            notificationServiceBus.showError(error.response);
          });
      }
    },
    orderItems(items) {
      courseModulesDocumentsService
        .UpdateOrder(
          this.moduleId,
          items.map((s) => s.IdCourseModuleDocument)
        )
        .then(() => {
          notificationServiceBus.showSuccessMessage(
            "Successo!",
            "Alterou a ordem com sucesso!"
          );
          this.Items = [...items];
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    resetInstance() {
      this.isModalContentOpen = false;
      this.Instance = {
        OrderId: 1,
        Name: "",
        IdCourseModuleDocument: 0,
        IdCourseModule: this.moduleId,
        File: {},
      };
    },
    deleteInstance(item) {
      var id = item.IdCourseModuleDocument;
      kendo.confirm("Tem a certeza que quer apagar o ficheiro?").done(() => {
        courseModulesDocumentsService
          .Delete(id)
          .then(() => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Apagou o ficheiro com sucesso!"
            );
            this.Items = this.Items.filter(
              (s) => s.IdCourseModuleDocument !== id
            );
            this.resetInstance();
          })
          .catch((error) => {
            notificationServiceBus.showError(error.response);
          });
      });
    },
  },
  mounted() {
    var that = this;
    var element = `${this.gridIdentifier}-Table`;
    this.$el
      .querySelector(
        `#${this.gridIdentifier} > .k-grid-container > .k-grid-content > div > .k-grid-table > tbody`
      )
      .setAttribute("id", element);
    Sortable.create(document.getElementById(element), {
      handle: ".k-action-move",
      animation: 150,
      onEnd: function (evt) {
        var arr = [...that.Items];
        var old = arr[evt.oldIndex];
        arr = arr
          .slice(0, evt.oldIndex)
          .concat(arr.slice(evt.oldIndex + 1, arr.length));
        arr.splice(evt.newIndex, 0, old);
        that.Items = [...[]];
        that.orderItems(arr);
      },
    });
  },
};
</script>

<style></style>
