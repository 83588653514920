var Basic = { TypeId: 1, Name: "Basic" };
var Short = { TypeId: 2, Name: "Short" };
var Medium = { TypeId: 3, Name: "Medium" };
var Long = { TypeId: 4, Name: "Long" };
var Complementary = { TypeId: 5, Name: "Complementary" };

export const PorfolioDurationTypes = [
  Basic,
  Short,
  Medium,
  Long,
  Complementary,
];
