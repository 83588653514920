<template>
  <v-dialog
    v-if="content"
    v-model="isModalTestOpen"
    persistent
    max-width="1024px"
  >
    <v-card>
      <v-card-title>
        <div class="headline">Gerir Teste</div>
      </v-card-title>
      <v-card-text>
        <v-alert
          :value="true"
          :color="isTestValid ? 'success' : 'error'"
          icon="new_releases"
          class="mb-2"
        >
          O teste possui um total de {{ Items.length }} perguntas. Cada teste
          será composto por sendo {{ content.TestMinQuestions }} perguntas.
        </v-alert>
        <div class="k-grid k-grid-toolbar">
          <v-btn
            :disabled="isAdding"
            color="accent"
            @click.prevent="onCreateUniqueHandler"
            >Adicionar pergunta opção única</v-btn
          >
          <v-btn
            :disabled="isAdding"
            color="accent"
            @click.prevent="onCreateMultipleHandler"
            >Adicionar pergunta opção múltipla</v-btn
          >
        </div>
        <Grid
          ref="testGrid"
          :style="{ height: 'auto' }"
          :data-items="Items"
          :columns="Columns"
          :edit-field="'inEdit'"
          @itemchange="itemChange"
          :detail="cellTemplate"
          @expandchange="expandChange"
          :expand-field="'expanded'"
        >
          <template slot="titleQuestionCell" slot-scope="{ props }">
            <td :class="props.className">
              <div
                v-if="!props.dataItem.inEdit"
                v-html="props.dataItem.Title"
                class="question-title-body"
              ></div>
              <kendo-editor
                v-else
                :resizable-content="false"
                :resizable-toolbar="false"
                v-model="props.dataItem.Title"
                :tools="kendoEditorTools"
                rows="5"
              ></kendo-editor>
            </td>
          </template>
          <template slot="typeCell" slot-scope="{ props }">
            <td :class="props.className">
              <strong>{{
                props.dataItem.TypeId === 1 ? "Opção única" : "Opção múltipla"
              }}</strong>
            </td>
          </template>
          <template slot="editCell" slot-scope="{ props }">
            <td :class="props.className">
              <button
                class="k-button k-button-icontext"
                @click="rowEditHandler(props.dataItem)"
              >
                <span
                  :class="{
                    'icon-mode_edit': true,
                    'icon-add':
                      props.dataItem.IdCourseContentQuestion == 0 && isAdding,
                  }"
                ></span>
              </button>
            </td>
          </template>
          <template slot="removeCell" slot-scope="{ props }">
            <td :class="props.className">
              <button
                class="k-button k-button-icontext"
                @click="rowRemoveHandler(props.dataItem)"
              >
                <span class="icon-close"></span>
              </button>
            </td>
          </template>
          <GridNoRecords>Não existem perguntas</GridNoRecords>
        </Grid>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="isAdding" color="white" @click="close"
          >Cancelar</v-btn
        >
        <v-btn :disabled="!isTestValid" color="success" @click="close"
          >Concluir</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import * as kendo from "@progress/kendo-ui";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import courseContentQuestionsService from "@/services/api/courseContentQuestionsService";
import { KendoUI } from "@/settings";
import AnswersGridComponent from "../shared/answers.grid";
Vue.component("app-answers-grid", AnswersGridComponent);
const componentInstance = Vue.component("app-answers-grid-component", {
  props: ["dataItem"],
  template: `<app-answers-grid :question="dataItem"></app-answers-grid>`,
});

export default {
  props: ["content"],
  data() {
    return {
      cellTemplate: componentInstance,
      kendoEditorTools: KendoUI.SimpleRichEditorTools,
      isAdding: false,
      isModalTestOpen: true,
      isLoading: false,
      Items: [],
      editItem: null,
      Columns: [
        {
          field: "TypeId",
          title: "Tipo de Pergunta",
          cell: "typeCell",
          width: 125,
        },
        { field: "Title", title: "Pergunta", cell: "titleQuestionCell" },
        {
          cell: "editCell",
          filterable: false,
          sortable: false,
          width: 75,
          title: "",
        },
        {
          cell: "removeCell",
          filterable: false,
          sortable: false,
          width: 75,
          title: "",
        },
      ],
    };
  },
  computed: {
    isTestValid() {
      return (
        !this.isLoading &&
        !this.isAdding &&
        this.Items.length >= this.content.TestMinQuestions
      );
    },
  },
  async created() {
    await this.loadQuestions();
  },
  methods: {
    expandChange(ev) {
      Vue.set(ev.dataItem, ev.target.$props.expandField, ev.value);
    },
    itemChange: function (e) {
      const data = this.Items.slice();
      const index = data.findIndex(
        (d) => d.IdCourseContentQuestion === e.dataItem.IdCourseContentQuestion
      );
      data[index] = { ...data[index], [e.field]: e.value };
      this.Items = data;
      Vue.set(e.dataItem, e.field, e.value);
      this.editItem = { ...e.dataItem };
    },
    close() {
      this.isModalTestOpen = false;
      this.$emit("closeTest");
    },
    onCreateUniqueHandler() {
      if (this.isAdding) return;
      this.isAdding = true;
      const newRecord = {
        Title: "",
        expandField: false,
        inEdit: true,
        IdCourseContentQuestion: 0,
        IdCourseContent: this.content.IdCourseContent,
        OrderId: this.Items.length + 1,
        TypeId: 1,
      };
      const data = this.Items.slice();
      data.unshift(newRecord);
      this.Items = data;
      this.editItem = newRecord;
    },
    onCreateMultipleHandler() {
      if (this.isAdding) return;
      this.isAdding = true;
      const newRecord = {
        Title: "",
        expandField: false,
        inEdit: true,
        IdCourseContentQuestion: 0,
        IdCourseContent: this.content.IdCourseContent,
        OrderId: this.Items.length + 1,
        TypeId: 2,
      };
      const data = this.Items.slice();
      data.unshift(newRecord);
      this.Items = data;
      this.editItem = newRecord;
    },
    onResetQuestions() {
      this.editItem = null;
      this.editOriginal = null;
      this.isAdding = false;
      this.isLoading = false;
    },
    async rowEditHandler(dataItem) {
      if (this.isLoading) {
        return;
      }
      var item = { ...dataItem };
      delete item.inEdit;
      if (!this.isAdding && this.editItem) {
        await this.updateQuestion(item);
        return;
      }
      if (this.isAdding) {
        await this.createQuestion(dataItem);
      } else {
        dataItem.inEdit = true;
        this.editItem = dataItem;
      }
    },
    async rowRemoveHandler(dataItem) {
      if (this.isLoading) {
        return;
      }
      if (
        !this.isAdding &&
        dataItem.IdCourseContentQuestion > 0 &&
        !dataItem.inEdit
      ) {
        await this.deleteQuestion(dataItem);
      } else {
        dataItem.inEdit = false;
        this.Items = this.Items.filter((s) => s.IdCourseContentQuestion > 0);
        this.onResetQuestions();
      }
    },
    async createQuestion(item) {
      this.isLoading = true;
      courseContentQuestionsService
        .Create(item)
        .then(() => {
          notificationServiceBus.showSuccessMessage(
            "Successo!",
            "Adicionou a pergunta com sucesso!"
          );
          this.onResetQuestions();
          this.loadQuestions();
        })
        .catch((error) => {
          this.isLoading = false;
          notificationServiceBus.showError(error.response);
        });
    },
    async updateQuestion(item) {
      this.isLoading = true;
      courseContentQuestionsService
        .Update(item)
        .then(() => {
          notificationServiceBus.showSuccessMessage(
            "Successo!",
            "Atualizou a pergunta com sucesso!"
          );
          this.loadQuestions();
          this.onResetQuestions();
        })
        .catch((error) => {
          this.isLoading = false;
          notificationServiceBus.showError(error.response);
        });
    },
    async deleteQuestion(item) {
      var id = item.IdCourseContentQuestion;
      kendo.confirm("Tem a certeza que quer apagar pergunta?").done(() => {
        this.isLoading = true;
        courseContentQuestionsService
          .Delete(id)
          .then(() => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Apagou a pergunta com sucesso!"
            );
            this.onResetQuestions();
            this.Items = this.Items.filter(
              (s) => s.IdCourseContentQuestion !== item.IdCourseContentQuestion
            );
          })
          .catch((error) => {
            this.isLoading = false;
            notificationServiceBus.showError(error.response);
          });
      });
    },
    async loadQuestions() {
      await courseContentQuestionsService
        .Get({
          Filters: [
            {
              Field: "IdCourseContent",
              Operator: "eq",
              Value: this.content.IdCourseContent,
            },
          ],
          Order: [{ Field: "OrderId", Direction: "ASC" }],
        })
        .then((response) => {
          this.Items = response.data.Items.map((item) =>
            Object.assign(
              {
                expandField: false,
                inEdit:
                  this.editItem &&
                  item.IdCourseContentQuestion ===
                    this.editItem.IdCourseContentQuestion,
              },
              item
            )
          );
        });
    },
  },
};
</script>

<style scoped></style>
