<template>
  <section id="CatalogInsccriptiionsIndex">
    <app-page-title>
      <h1>Inscrições</h1>
    </app-page-title>
    <v-container>
      <app-inscriptions-grid></app-inscriptions-grid>
    </v-container>
  </section>
</template>

<script>
import SubscriptionsGridComponent from "@/components/grid/subscriptions.grid";

export default {
  components: {
    "app-inscriptions-grid": SubscriptionsGridComponent,
  },
};
</script>

<style></style>
