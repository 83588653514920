<template>
  <div>
    <div class="k-grid k-grid-toolbar">
      <app-form-modal
        titleButton="Adicionar Pergunta"
        class-icon="icon-add"
        :title-dialog="`${isNew ? 'Adicionar' : 'Editar'} Pergunta`"
        :confirmAction="saveInstance"
        :cancelAction="clearData"
        :formValid="!$v.Instance.$error"
        ref="modal"
      >
        <v-text-field
          v-model="Instance.Title"
          :error="$v.Instance.Title.$error"
          @blur="$v.Instance.Title.$touch()"
          label="Titulo da pergunta"
          required
        ></v-text-field>
        <v-text-field
          type="number"
          v-model="Instance.OrderId"
          :error="$v.Instance.OrderId.$error"
          @blur="$v.Instance.OrderId.$touch()"
          label="Ordem da pergunta"
          required
        ></v-text-field>
        <v-select
          v-model="Instance.TypeId"
          item-text="Name"
          item-value="TypeId"
          :items="surveyQuestionTypes"
          label="Tipo de pergunta"
        >
        </v-select>
      </app-form-modal>
    </div>
    <Grid
      :id="gridIdentifier"
      :style="{ height: 'auto' }"
      :data-items="Items"
      :columns="Columns"
      @edit="edit"
      @remove="remove"
    >
      <template slot="TypeCell" slot-scope="{ props }">
        <td :class="props.className">
          {{ getQuestionTypeName(props.dataItem) }}
        </td>
      </template>
      <GridNoRecords> Não existem perguntas </GridNoRecords>
    </Grid>
  </div>
</template>

<script>
import Sortable from "sortablejs";
import * as kendo from "@progress/kendo-ui";
import { required, minLength } from "vuelidate/lib/validators";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import surveyQuestionsService from "@/services/api/surveyQuestionsService";
import {
  SurveyQuestionTypes,
  GetTypeNameById,
} from "@/models/surveys-question-types";
import FormModalComponent from "@/components/modals/form-modal";
import EditCommandCell from "@/components/grid/edit-command.js";
import DeleteCommandCell from "@/components/grid/delete-command.js";
import OrderCommandCell from "@/components/grid/order-command.js";

export default {
  components: {
    "app-form-modal": FormModalComponent,
  },
  props: ["dataItem"],
  data() {
    return {
      surveyQuestionTypes: SurveyQuestionTypes,
      Instance: {
        IdSurveyQuestion: 0,
        OrderId: 1,
        Title: "",
        IdSurveyGroup: this.dataItem.IdSurveyGroup,
      },
      Items: [],
      Columns: [
        {
          cell: OrderCommandCell,
          filterable: false,
          sortable: false,
          width: 75,
          title: "Ordem",
        },
        { field: "Title", title: "Título" },
        { field: "TypeId", title: "Tipo", cell: "TypeCell" },
        {
          cell: EditCommandCell,
          filterable: false,
          sortable: false,
          width: 75,
          title: "",
        },
        {
          cell: DeleteCommandCell,
          filterable: false,
          sortable: false,
          width: 75,
          title: "",
        },
      ],
    };
  },
  validations: {
    Instance: {
      Title: {
        required,
        minLength: minLength(3),
      },
      OrderId: {
        required,
      },
    },
  },
  computed: {
    isNew() {
      return this.Instance != null && this.Instance.IdSurveyQuestion == 0;
    },
    gridIdentifier() {
      return `GridCategory${
        this.dataItem !== null ? this.dataItem.IdSurveyGroup : 0
      }`;
    },
  },
  methods: {
    getQuestionTypeName(item) {
      return GetTypeNameById(item.TypeId);
    },
    openModal() {
      this.$refs.modal.open();
    },
    clearData(callBack) {
      this.resetInstance();
      callBack(true);
    },
    edit(item) {
      this.Instance = { ...item.dataItem };
      this.openModal();
    },
    remove(item) {
      this.deleteInstance(item.dataItem);
    },
    resetInstance() {
      this.Instance = {
        IdSurveyQuestion: 0,
        Title: "",
        TypeId: 1,
        orderId: 1,
        IdSurveyGroup:
          this.dataItem === null ? null : this.dataItem.IdSurveyGroup,
      };
    },
    async loadItems() {
      let options = {
        Filters: [],
        Order: [{ Field: "OrderId", Direction: "ASC" }],
      };
      if (this.dataItem) {
        options.Filters.push({
          Field: "IdSurveyGroup",
          Operation: "eq",
          Value: this.dataItem.IdSurveyGroup,
        });
      } else {
        options.Filters.push({
          Field: "IdSurveyGroup",
          Operation: "eq",
          Value: null,
        });
      }
      await surveyQuestionsService
        .Get(options)
        .then((res) => {
          this.Items = res.data.Items;
          this.resetInstance();
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    orderItems(items) {
      surveyQuestionsService
        .UpdateOrder(
          this.dataItem !== null ? this.dataItem.IdSurveyGroup : null,
          items
        )
        .then(() => {
          notificationServiceBus.showSuccessMessage(
            "Successo!",
            "alterou a ordem com sucesso!"
          );
          this.loadItems();
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    async saveInstance(callBack) {
      if (this.isNew) {
        await surveyQuestionsService
          .Create(this.Instance)
          .then((response) => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Criou a pergunta com sucesso!"
            );
            this.Items.push(response.data.Instance);
            this.resetInstance();
            callBack(true);
          })
          .catch((error) => {
            callBack(false);
            notificationServiceBus.showError(error.response);
          });
      } else {
        await surveyQuestionsService
          .Update(this.Instance)
          .then((response) => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Editou a pergunta com sucesso!"
            );
            const index = this.Items.findIndex(
              (s) => s.IdSurveyQuestion == this.Instance.IdSurveyQuestion
            );
            if (index >= 0) {
              this.Items[index] = response.data.Instance;
            }
            this.resetInstance();
            callBack(true);
          })
          .catch((error) => {
            callBack(false);
            notificationServiceBus.showError(error.response);
          });
      }
    },
    deleteInstance(item) {
      var id = item.IdSurveyQuestion;
      kendo.confirm("Tem a certeza que quer apagar a pergunta?").done(() => {
        surveyQuestionsService
          .Delete(id)
          .then(() => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Apagou a pergunta com sucesso!"
            );
            this.Items = this.Items.filter((s) => s.IdSurveyQuestion !== id);
            this.resetInstance();
          })
          .catch((error) => {
            notificationServiceBus.showError(error.response);
          });
      });
    },
  },
  async created() {
    this.resetInstance();
    await this.loadItems();
  },
  mounted() {
    var that = this;
    var element = `${this.gridIdentifier}-Table`;
    this.$el
      .querySelector(
        `#${this.gridIdentifier} > .k-grid-container > .k-grid-content > div > .k-grid-table > tbody`
      )
      .setAttribute("id", element);
    Sortable.create(document.getElementById(element), {
      handle: ".k-action-move",
      animation: 150,
      onEnd: function (evt) {
        var arr = [...that.Items];
        var old = arr[evt.oldIndex];
        arr = arr
          .slice(0, evt.oldIndex)
          .concat(arr.slice(evt.oldIndex + 1, arr.length));
        arr.splice(evt.newIndex, 0, old);
        that.Items = [...[]];
        that.orderItems(arr.map((s) => s.IdSurveyQuestion));
      },
    });
  },
};
</script>

<style></style>
