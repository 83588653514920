export const Shot = {
  TypeId: 6,
  Name: "E-Learning",
};
export const Elearn = {
  TypeId: 1,
  Name: "B-Learning",
};
export const InternalPresence = {
  TypeId: 2,
  Name: "Presencial interno",
};
export const ExternalPresence = {
  TypeId: 3,
  Name: "Presencial externo",
};
export const OnTheJob = {
  TypeId: 4,
  Name: "On The job",
};
export const Live = {
  TypeId: 5,
  Name: "Live",
};
// export const Shot = {
//   TypeId: 6,
//   Name: "Shot",
// };
export const ElearnSync = {
  TypeId: 7,
  Name: "E-Learning Síncrono",
};

export const CourseArchitectureTypes = [
  Elearn,
  ElearnSync,
  InternalPresence,
  ExternalPresence,
  Live,
];

export function GetTypeNameById(id) {
  for (let index = 0; index < CourseArchitectureTypes.length; index++) {
    if (CourseArchitectureTypes[index].TypeId === id) {
      return CourseArchitectureTypes[index].Name;
    }
  }
  return "";
}
