export const Free = {
  TypeId: 1,
  Name: "Livre - navegação entre módulos e conteúdos é livre",
};
export const Sequential = {
  TypeId: 2,
  Name: "Sequencial - é necessário completar cada módulo / conteúdo sequencialmente",
};
export const Unique = {
  TypeId: 3,
  Name: "Conteúdo único (apenas um módulo)",
};
export const CourseMethodologyTypes = [Free, Sequential, Unique];

export function GetTypeNameById(id) {
  for (let index = 0; index < CourseMethodologyTypes.length; index++) {
    if (CourseMethodologyTypes[index].TypeId === id) {
      return CourseMethodologyTypes[index].Name;
    }
  }
  return "";
}
