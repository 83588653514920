export const Table_36 = [
  {
    Code: "01",
    Text: "Nível 1",
    Hint: "Corresponde ao 2.º ciclo do ensino básico.",
  },
  {
    Code: "02",
    Text: "Nível 2",
    Hint: "Corresponde ao 3.º ciclo do ensino básico obtido no ensino regular ou por percursos de dupla certificação.",
  },
  {
    Code: "03",
    Text: "Nível 3",
    Hint: "Corresponde ao ensino secundário vocacionado para prosseguimento de estudos de nível superior.",
  },
  {
    Code: "04",
    Text: "Nível 4",
    Hint: "Corresponde ao ensino secundário obtido por percursos de dupla certificação ou ensino secundário vocacionado para prosseguimento de estudos de nível superior acrescido de estágio profissional — mínimo de 6 meses.",
  },
  {
    Code: "05",
    Text: "Nível 5",
    Hint: "Corresponde a qualificação de nível pós-secundário não superior com créditos para prosseguimento de estudos de nível superior.",
  },
  {
    Code: "06",
    Text: "Nível 6",
    Hint: "Corresponde a licenciatura.",
  },
  {
    Code: "07",
    Text: "Nível 7",
    Hint: "Corresponde a mestrado.",
  },
  {
    Code: "08",
    Text: "Nível 8",
    Hint: "Corresponde a doutoramento.",
  },
  {
    Code: "09",
    Text: "Sem atribuição de nível",
    Hint: "Sem atribuição de nível.",
  },
  {
    Code: "99",
    Text: "Desconhecido",
    Hint: "",
  },
];

export function GetTypeTextById(id) {
  for (let index = 0; index < Table_36.length; index++) {
    if (Table_36[index].Code === id) {
      return Table_36[index].Text;
    }
  }
  return "";
}
