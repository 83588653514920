export const Basic = {
  TypeId: 1,
  Name: "Básico",
};
export const Intermedium = {
  TypeId: 2,
  Name: "Intermédio",
};
export const Advanced = {
  TypeId: 3,
  Name: "Avançado",
};
export const NA = {
  TypeId: 4,
  Name: "N/A",
};
export const CourseLevelTypes = [NA, Basic, Intermedium, Advanced];

export function GetTypeNameById(id) {
  for (let index = 0; index < CourseLevelTypes.length; index++) {
    if (CourseLevelTypes[index].TypeId === id) {
      return CourseLevelTypes[index].Name;
    }
  }
  return "";
}
