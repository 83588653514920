<!-- eslint-disable max-len -->
<template>
  <div>
    <app-modal-module ref="moduleModal" @onCreated="moduleCreated"></app-modal-module>
    <v-container>
      <v-layout row wrap>
        <v-flex xs12 sm12 md6 px-3 v-if="!loading">
          <p class="mb-3">Performance</p>
          <v-card>
            <v-card-title>Inscrições (total: {{ total }})</v-card-title>
            <v-card-text>
              <kendo-chart id="pieChart" :legend-position="'bottom'" :series="pieChart.series" :series-defaults-labels-visible="true" :series-defaults-labels-position="'insideEnd'" :series-defaults-labels-template="pieChart.labelTemplate" :theme="'material'"></kendo-chart>
            </v-card-text>
          </v-card>
          <v-card>
            <v-card-title>Últimas incrições</v-card-title>
            <v-card-text>
              <kendo-chart id="barChart" :legend-visible="false" :series-defaults-type="'column'" :series="barChart.seriesBar" :category-axis="barChart.categoryAxisBar" :value-axis="barChart.valueAxisBar" :tooltip="barChart.tooltipBar" :theme="'material'"></kendo-chart>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12 sm12 md6 px-3>
          <div class="pl-4">
            <p class="mb-3">Ficha Formativa</p>
            <v-container grid-list-lg class="p-0">
              <v-layout>
                <v-flex xs12 sm4>
                  <img v-if="this.course.PhotoSrc" class="img-fluid" :src="GetRelativePath(
      this.course.PhotoSrc,
      'h=150&h=150&mode=crop'
    )
      " aspect-ratio="1" />
                  <p class="text-bolder mt-3">Duração</p>
                  <p>{{ courseHoursLength.toFixed(2) }} h</p>
                </v-flex>
                <v-flex xs12 sm8 class="pl-4">
                  <!-- <p class="text-bolder">Objetivos</p> -->
                  <!-- <p>{{ course.Objectives }}</p> -->
                  <p v-html="course.Objectives"></p>

                  <p class="text-bolder mt-4">Conteúdo Programático</p>
                  <p v-html="course.ContentsInfo"></p>

                  <p class="text-bolder mt-4">Destinatários</p>
                  <p v-html="course.Recipients"></p>

                  <p class="text-bolder mt-4">Domínio da Formação</p>
                  <p v-if="course.CourseInfos.length > 0">
                    {{ domain }}
                  </p>
                  <p v-else>Sem domínio definido</p>
                </v-flex>
              </v-layout>
            </v-container>
            <p class="mb-3">Módulos</p>
            <ul v-if="course.Modules" class="list-unstyled modules-list">
              <li v-for="m in course.Modules" :key="m.IdCourseModule" class="mb-3">
                <div class="mr-3">
                  <i class="icon-flag icon_dark_gray" />
                </div>
                <span class="size--s14 text-uppercase">{{ m.Name }}</span>
                <span class="ml-auto mr-4">
                  <i class="icon-users size--s25 icon_green" />
                </span>
              </li>
              <li>
                <span class="ml-3 size--s14 text-uppercase mr-auto">Fim</span>
                <span class="ml-auto">
                  <button @click.prevent="addModule" class="v-btn accent text-white">
                    Adicionar módulo
                  </button>
                </span>
              </li>
            </ul>
            <div v-if="course.RelatedCourses.length > 0" class="mt-3">
              <p class="mb-3">Formações relacionadas</p>
              <ul>
                <li v-for="c in course.RelatedCourses" :key="c.IdCourse" class="mb-2">
                  <span class="size--s14 text-uppercase">{{ c.Code }} - {{ c.Name }}</span>
                </li>
              </ul>
            </div>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import router from "@/router/router";
import ModalModuleComponent from "../shared/modal-module";
import coursesService from "@/services/api/coursesService";
import UrlBuilder from "@/utilities/url-builder";
import { GetTypeTextById } from "@/models/formation-domain";

export default {
  components: {
    "app-modal-module": ModalModuleComponent,
  },
  props: ["course"],
  data () {
    return {
      loading: true,
      pieChart: {
        series: [
          {
            type: "pie",
            data: [
              {
                category: "Não iniciados",
                value: 0,
              },
              {
                category: "Concluídos",
                value: 0,
              },
              { category: "Em Formação", value: 0 },
            ],
          },
        ],
        labelTemplate: "#= category #: #= value #",
      },
      barChart: {
        seriesBar: [
          {
            name: "Inscrições por mês",
            data: [],
          },
        ],
        valueAxisBar: [
          {
            line: {
              visible: true,
            },
            minorGridLines: {
              visible: false,
            },
            labels: {
              rotation: "auto",
            },
          },
        ],
        categoryAxisBar: {
          categories: [],
          majorGridLines: {
            visible: false,
          },
        },
        tooltipBar: {
          visible: true,
          template: "Total inscrições: #= value #",
        },
      },
      total: 0,
    };
  },
  computed: {
    domain () {
      var name = "";
      this.course.CourseInfos.forEach((element) => {
        if (element.Code === "Domain") {
          name = GetTypeTextById(element.Value);
          return name;
        }
      });
      return name;
    },
    courseHoursLength () {
      return this.course.MinutesFormation / 60;
    },
  },
  watch: {
    course: function () {
      this.loading = true;
      this.loadStatistics();
    },
  },
  methods: {
    GetRelativePath (path) {
      return `${UrlBuilder.GetPath(path)}`;
    },
    async loadStatistics () {
      await coursesService
        .GetStatistics(this.course.IdCourse)
        .then((res) => {
          this.total = 0;
          this.total = res.data.Total;
          this.pieChart.series[0].data[0].value =
            res.data.InscriptionsNotInitiated;
          this.pieChart.series[0].data[1].value =
            res.data.InscriptionsCompleted;
          this.pieChart.series[0].data[2].value = res.data.InscriptionsCurrent;
          this.barChart.seriesBar[0].data = res.data.InscriptionsByMonth.map(
            (s) => s.Value
          );
          this.barChart.categoryAxisBar.categories =
            res.data.InscriptionsByMonth.map((s) => s.Title);
        })
        .finally(() => (this.loading = false));
    },
    addModule () {
      this.$refs.moduleModal.open();
    },
    moduleCreated (item) {
      this.course.Modules.push(item);
      this.$emit("modulesChanged", this.course.Modules);
      router.push(`/catalog/courses/${this.$route.params.idCourse}/contents`);
    },
  },
  async created () {
    await this.loadStatistics();
  },
};
</script>

<style scoped>
.modules-list li:last-of-type:before {
  content: "\E91C" !important;
}
</style>
