<template>
  <div>
    <app-questions-grid
      v-if="testContent"
      :content="testContent"
      @closeTest="testContent = null"
    ></app-questions-grid>
    <v-dialog v-model="isModalContentSwitchOpen" persistent max-width="40%">
      <v-form @submit.prevent="onSaveContentSwitch">
        <v-card>
          <v-card-title>
            <span class="headline">Alterar tipo de Conteúdo</span>
          </v-card-title>
          <v-card-text>
            <v-select
              label="Tipo de conteúdo"
              v-model="Instance.TypeId"
              :items="courseContentTypes"
              item-text="Name"
              item-value="TypeId"
              required
            ></v-select>
          </v-card-text>
          <v-card-actions>
            <v-btn color="white" @click="isModalContentSwitchOpen = false"
              >Cancelar</v-btn
            >
            <v-btn
              :loading="isLoading"
              color="accent"
              class="ml-auto"
              type="submit"
              >Confirmar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="isModalContentOpen" persistent max-width="80%">
      <v-form @submit.prevent="onSaveContent">
        <v-card>
          <v-card-title>
            <span class="headline">Conteúdo</span>
          </v-card-title>
          <v-stepper v-model="stepId">
            <v-stepper-header>
              <v-stepper-step :complete="stepId > 1" step="1"
                >Dados do Conteúdo</v-stepper-step
              >
              <v-divider></v-divider>
              <v-stepper-step :complete="stepId > 2" step="2">
                Descrição
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-card-text>
                  <div class="custom-grid">
                    <v-layout wrap row>
                      <v-flex xs12>
                        <v-checkbox
                          label="Publicar"
                          :input-value="Instance.IsPublic"
                          v-model="Instance.IsPublic"
                        ></v-checkbox>
                      </v-flex>
                      <v-flex xs6>
                        <v-select
                          :disabled="Instance.IdCourseContent > 0"
                          label="Tipo de conteúdo"
                          v-model="Instance.TypeId"
                          :items="courseContentTypes"
                          item-text="Name"
                          item-value="TypeId"
                          required
                        ></v-select>
                      </v-flex>
                      <v-flex xs6>
                        <v-text-field
                          label="Tempo minimo"
                          type="number"
                          v-model="Instance.MinutesRequired"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12>
                        <v-text-field
                          v-model="Instance.Name"
                          @blur="$v.Instance.Name.$touch()"
                          :error="$v.Instance.Name.$error"
                          label="Nome do conteúdo"
                          required
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12>
                        <v-textarea
                          label="Descrição"
                          v-model="Instance.Description"
                          hint="Designação do conteúdo"
                        ></v-textarea>
                      </v-flex>
                      <!-- <v-flex xs12>
                        <v-checkbox
                          v-model="Instance.IsPublic"
                          :label="`Publicado`"
                        ></v-checkbox>
                      </v-flex>-->
                    </v-layout>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="white" @click="closeContentModal"
                    >Cancelar</v-btn
                  >
                  <v-btn
                    :disabled="
                      Instance.TypeId === 0 || Instance.Name.length < 3
                    "
                    color="accent"
                    class="ml-auto"
                    @click="stepId = 2"
                    >Avançar</v-btn
                  >
                </v-card-actions>
              </v-stepper-content>
              <v-stepper-content step="2">
                <v-card>
                  <div v-if="stepId == 2">
                    <v-card-text>
                      <!-- HTML -->
                      <div
                        class="mb-4"
                        v-if="Instance.TypeId === ContentTypes.Html.TypeId"
                      >
                        <kendo-editor
                          :resizable-content="true"
                          :resizable-toolbar="true"
                          v-model="Instance.Body"
                          rows="20"
                          style="height: 60vh"
                        >
                          <!-- <kendo-editor-tool
                            v-for="n in editorTools"
                            :key="n"
                            :name="n"
                          ></kendo-editor-tool> -->
                        </kendo-editor>
                      </div>
                      <!-- VIDEO -->
                      <div
                        class="mb-4"
                        v-if="Instance.TypeId === ContentTypes.Video.TypeId"
                      >
                        <v-label>Ficheiro</v-label>
                        <app-video-uploader
                          :async="true"
                          @onProgress="isLoading = true"
                          @onError="
                            isLoading = false;
                            IsFileValid = false;
                            Instance.File = null;
                          "
                          @onSuccess="onFileChange"
                        ></app-video-uploader>
                      </div>
                      <!-- Audio -->
                      <div
                        class="mb-4"
                        v-if="Instance.TypeId === ContentTypes.Audio.TypeId"
                      >
                        <v-label>Ficheiro</v-label>
                        <app-audio-uploader
                          :async="true"
                          @onProgress="isLoading = true"
                          @onError="
                            isLoading = false;
                            IsFileValid = false;
                            Instance.File = null;
                          "
                          @onSuccess="onFileChange"
                        ></app-audio-uploader>
                      </div>
                      <!-- POWERPOINT -->
                      <div
                        class="mb-4"
                        v-if="
                          Instance.TypeId === ContentTypes.Powerpoint.TypeId
                        "
                      >
                        <v-label>Ficheiro</v-label>
                        <app-powerpoint-uploader
                          :async="true"
                          @onProgress="isLoading = true"
                          @onError="
                            isLoading = false;
                            IsFileValid = false;
                            Instance.File = null;
                          "
                          @onSuccess="onFileChange"
                        ></app-powerpoint-uploader>
                      </div>
                      <!-- TESTE -->
                      <div
                        class="mb-3 custom-grid"
                        v-if="Instance.TypeId === ContentTypes.Test.TypeId"
                      >
                        <v-layout wrap row>
                          <v-flex xs6>
                            <v-text-field
                              label="Numero de questões minimas"
                              v-model="Instance.TestMinQuestions"
                              min="1"
                              @blur="$v.Instance.TestMinQuestions.$touch()"
                              :error="$v.Instance.TestMinQuestions.$error"
                              type="number"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs6>
                            <v-text-field
                              label="% de aprovação minima"
                              v-model="Instance.TestMinApproval"
                              min="0"
                              max="100"
                              @blur="$v.Instance.TestMinApproval.$touch()"
                              :error="$v.Instance.TestMinApproval.$error"
                              type="number"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs6>
                            <v-radio-group
                              v-model="Instance.TestIsQuestionsRandom"
                              :mandatory="true"
                              label="Perguntas serão aleatórias?"
                            >
                              <v-radio label="Sim" :value="true"></v-radio>
                              <v-radio label="Não" :value="false"></v-radio>
                            </v-radio-group>
                          </v-flex>
                          <v-flex xs6>
                            <v-radio-group
                              v-model="Instance.TestShowsFinalResult"
                              :mandatory="true"
                              label="Mostrar nota?"
                            >
                              <v-radio label="Sim" :value="true"></v-radio>
                              <v-radio label="Não" :value="false"></v-radio>
                            </v-radio-group>
                          </v-flex>
                        </v-layout>
                      </div>
                      <!-- HTML5 -->
                      <div
                        class="mb-4"
                        v-if="Instance.TypeId === ContentTypes.Html5.TypeId"
                      >
                        <v-label>Ficheiro</v-label>
                        <app-zip-uploader
                          :async="true"
                          @onProgress="isLoading = true"
                          @onError="
                            isLoading = false;
                            IsFileValid = false;
                            Instance.File = null;
                          "
                          @onSuccess="onFileChange"
                        ></app-zip-uploader>
                      </div>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        @click="closeContentModal"
                        color="white"
                        class="mr-2"
                        :disabled="isLoading"
                        >Cancelar</v-btn
                      >
                      <v-btn
                        @click="
                          stepId = 1;
                          Instance.File = null;
                        "
                        color="white"
                        :disabled="isLoading"
                        >Retroceder</v-btn
                      >
                      <v-btn
                        :loading="isLoading"
                        color="accent"
                        class="ml-auto"
                        type="submit"
                        :disabled="$v.Instance.$error || !IsFileValid"
                        >Confirmar</v-btn
                      >
                    </v-card-actions>
                  </div>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card>
      </v-form>
    </v-dialog>
    <div class="k-grid k-grid-toolbar">
      <v-btn color="accent" title="Adicionar" @click.prevent="addContentModal"
        >Adicionar Conteúdo</v-btn
      >
    </div>
    <Grid
      :id="gridIdentifier"
      :style="{ height: 'auto' }"
      :data-items="Items"
      :columns="Columns"
      @edit="edit"
      @remove="remove"
    >
      <template slot="EditContentTypeCell" slot-scope="{ props }">
        <td :class="props.className">
          <button
            class="k-button k-button-icontext"
            @click="openSwitchModal(props.dataItem)"
          >
            <span class="icon-flag"></span>
          </button>
        </td>
      </template>
      <template slot="ContentTypeNameCell" slot-scope="{ props }">
        <td :class="props.className">
          {{ getcourseContentTypeName(props.dataItem) }}
        </td>
      </template>
      <template slot="FileCell" slot-scope="{ props }">
        <td :class="props.className">{{ getFileNameSrc(props.dataItem) }}</td>
      </template>
      <template slot="detailCell" slot-scope="{ props }">
        <td :class="props.className">
          <button
            v-if="ContentTypes.Test.TypeId == props.dataItem.TypeId"
            @click.prevent="manageTest(props.dataItem)"
            class="k-button k-button-icontext"
          >
            <span class="icon-home"></span>
          </button>
          <a
            target="_blank"
            :href="props.dataItem.FilenameSrc"
            v-if="
              ContentTypes.Test.TypeId != props.dataItem.TypeId &&
              ContentTypes.Html.TypeId != props.dataItem.TypeId
            "
            class="k-button k-button-icontext"
          >
            <span class="icon-download"></span>
          </a>
        </td>
      </template>
      <GridNoRecords>Não existem conteúdos neste módulo</GridNoRecords>
    </Grid>
  </div>
</template>

<script>
import Sortable from "sortablejs";
import * as kendo from "@progress/kendo-ui";
import {
  required,
  minLength,
  maxValue,
  minValue,
} from "vuelidate/lib/validators";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import courseContentsService from "@/services/api/courseContentsService";
import {
  Html,
  Video,
  Powerpoint,
  Test,
  Html5,
  Audio,
  CourseContentTypes,
  GetTypeNameById,
} from "@/models/course-content-types";

import EditCommandCell from "@/components/grid/edit-command.js";
import DeleteCommandCell from "@/components/grid/delete-command.js";
import OrderCommandCell from "@/components/grid/order-command.js";
import QuestionsGridComponent from "./questions.grid";
import PowerpointAsyncUploaderComponent from "@/components/forms/uploaders/powerpoint";
import ZipAsyncUploaderComponent from "@/components/forms/uploaders/zip";
import VideoAsyncUploaderComponent from "@/components/forms/uploaders/video";
import AudioAsyncUploaderComponent from "@/components/forms/uploaders/audio";
import { KendoUI } from "@/settings";

export default {
  components: {
    "app-questions-grid": QuestionsGridComponent,
    "app-powerpoint-uploader": PowerpointAsyncUploaderComponent,
    "app-video-uploader": VideoAsyncUploaderComponent,
    "app-audio-uploader": AudioAsyncUploaderComponent,
    "app-zip-uploader": ZipAsyncUploaderComponent,
  },
  props: ["moduleId"],
  validations: {
    Instance: {
      Name: {
        required,
        minLength: minLength(4),
      },
      TestMinApproval: {
        maxValue: maxValue(100),
        minValue: minValue(0),
      },
      TestMinQuestions: {
        minValue: minValue(1),
      },
    },
  },
  data() {
    return {
      IsFileValid: true,
      Instance: { IsPublic: true },
      testContent: null,
      ContentTypes: {
        Html,
        Video,
        Powerpoint,
        Test,
        Html5,
        Audio,
      },
      // imageBrowser: {
      //   filetype: ".jpg",
      //   //The Path variable is the directory of the file(s) are.
      //   transport: {
      //     read: UrlBuider.GetPath(`/api/ImageBrowser`),
      //     // destroy: UrlBuider.GetPath(`/api/ImageBrowser`),
      //     // create: UrlBuider.GetPath(`/api/ImageBrowser`),
      //     uploadUrl: UrlBuider.GetPath(`/api/ImageBrowser`),
      //     // thumbnailUrl: UrlBuider.GetPath(`/api/ImageBrowser`),
      //     imageUrl: UrlBuider.GetPath(`/api/ImageBrowser`)
      //   }
      // },
      editorTools: KendoUI.BasicRichEditorTools,
      stepId: 1,
      isModalContentOpen: false,
      isModalContentSwitchOpen: false,
      isLoading: false,
      Items: [],
      Columns: [
        {
          cell: OrderCommandCell,
          filterable: false,
          sortable: false,
          width: 75,
          title: "Ordem",
        },
        {
          field: "TypeId",
          title: "Tipo do Conteúdo",
          cell: "ContentTypeNameCell",
        },
        { field: "Name", title: "Nome do Conteúdo" },
        { cell: "FileCell", title: "Nome do Ficheiro" },
        {
          cell: "detailCell",
          filterable: false,
          sortable: false,
          width: 75,
          title: "",
        },
        {
          cell: "EditContentTypeCell",
          filterable: false,
          sortable: false,
          width: 75,
          title: "",
        },
        {
          cell: EditCommandCell,
          filterable: false,
          sortable: false,
          width: 75,
          title: "",
        },
        {
          cell: DeleteCommandCell,
          filterable: false,
          sortable: false,
          width: 75,
          title: "",
        },
      ],
    };
  },
  computed: {
    isNew() {
      return this.Instance != null && this.Instance.IdCourseContent == 0;
    },
    courseContentTypes() {
      return CourseContentTypes;
    },
    courseContentTypeName() {
      return GetTypeNameById(this.Instance.TypeId);
    },
    gridIdentifier() {
      return `GridModule${this.moduleId}`;
    },
  },
  async created() {
    this.resetInstance();
    await courseContentsService
      .Get({
        Filters: [
          {
            Field: "IdCourseModule",
            Operator: "eq",
            Value: this.moduleId,
          },
        ],
        Order: [{ Field: "OrderId", Direction: "ASC" }],
      })
      .then((response) => {
        this.Items = response.data.Items;
      });
  },
  methods: {
    onFileChange(file) {
      this.isLoading = false;
      this.IsFileValid = true;
      this.Instance.File = file;
    },
    manageTest(item) {
      this.testContent = item;
      this.openTestModal();
    },
    openTestModal() {
      this.closeContentModal();
    },
    closeTestModal() {
      this.testContent = null;
    },
    openContentModal() {
      this.closeTestModal();
      this.stepId = 1;
      this.isModalContentOpen = true;
    },
    closeContentModal() {
      this.isModalContentOpen = false;
    },
    addContentModal() {
      this.resetInstance();
      this.openContentModal();
    },
    openSwitchModal(item) {
      this.Instance = { ...item };
      this.closeContentModal();
      this.isModalContentSwitchOpen = true;
    },
    edit(item) {
      this.Instance = { ...item.dataItem };
      this.openContentModal();
    },
    remove(item) {
      this.deleteInstance(item.dataItem);
    },
    getFileNameSrc(item) {
      const file = item.FilenameSrc
        ? item.FilenameSrc.substring(item.FilenameSrc.lastIndexOf("/"))
        : null;
      return file ? file.substring(1) : "-";
    },
    getcourseContentTypeName(item) {
      return GetTypeNameById(item.TypeId);
    },
    async onSaveContent() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      if (this.isNew) {
        courseContentsService
          .Create(this.Instance)
          .then((response) => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Adicionou o modulo com sucesso!"
            );
            this.isLoading = false;
            this.Items.push(response.data.Instance);
            this.closeContentModal();
            this.resetInstance();
          })
          .catch((error) => {
            this.isLoading = false;
            notificationServiceBus.showError(error.response);
          });
      } else {
        await courseContentsService
          .Update(this.Instance)
          .then((response) => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Editou o modulo com sucesso!"
            );
            this.isLoading = false;
            const index = this.Items.findIndex(
              (s) => s.IdCourseContent == this.Instance.IdCourseContent
            );
            if (index >= 0) {
              this.Items[index] = { ...response.data.Instance };
            }
            this.closeContentModal();
            this.resetInstance();
          })
          .catch((error) => {
            this.isLoading = false;
            notificationServiceBus.showError(error.response);
          });
      }
    },
    async onSaveContentSwitch() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      await courseContentsService
        .Update(this.Instance)
        .then((response) => {
          notificationServiceBus.showSuccessMessage(
            "Successo!",
            "Editou o tipo de conteúdo com sucesso!"
          );
          this.isLoading = false;
          const index = this.Items.findIndex(
            (s) => s.IdCourseContent == this.Instance.IdCourseContent
          );
          if (index >= 0) {
            this.Items[index] = { ...response.data.Instance };
          }
          this.isModalContentSwitchOpen = false;
          this.resetInstance();
        })
        .catch((error) => {
          this.isLoading = false;
          notificationServiceBus.showError(error.response);
        });
    },
    orderItems(items) {
      courseContentsService
        .UpdateOrder(
          this.moduleId,
          items.map((s) => s.IdCourseContent)
        )
        .then(() => {
          notificationServiceBus.showSuccessMessage(
            "Successo!",
            "Alterou a ordem com sucesso!"
          );
          this.Items = [...items];
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    resetInstance() {
      this.stepId = 1;
      this.IsFileValid = true;
      this.Instance = {
        OrderId: 1,
        IdCourseContent: 0,
        IdCourseModule: this.moduleId,
        Name: "",
        TypeId: 0,
        Description: "",
        MinutesRequired: 0,
        MinutesFormation: 0,
        IsPublic: true,
        Body: "",
      };
    },
    deleteInstance(item) {
      var id = item.IdCourseContent;
      kendo.confirm("Tem a certeza que quer apagar o conteúdo?").done(() => {
        courseContentsService
          .Delete(id)
          .then(() => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Apagou o conteúdo com sucesso!"
            );
            this.Items = this.Items.filter((s) => s.IdCourseContent !== id);
            this.resetInstance();
          })
          .catch((error) => {
            notificationServiceBus.showError(error.response);
          });
      });
    },
  },
  mounted() {
    var that = this;
    var element = `${this.gridIdentifier}-Table`;
    this.$el
      .querySelector(
        `#${this.gridIdentifier} > .k-grid-container > .k-grid-content > div > .k-grid-table > tbody`
      )
      .setAttribute("id", element);
    Sortable.create(document.getElementById(element), {
      handle: ".k-action-move",
      animation: 150,
      onEnd: function (evt) {
        var arr = [...that.Items];
        var old = arr[evt.oldIndex];
        arr = arr
          .slice(0, evt.oldIndex)
          .concat(arr.slice(evt.oldIndex + 1, arr.length));
        arr.splice(evt.newIndex, 0, old);
        that.Items = [...[]];
        that.orderItems(arr);
      },
    });
  },
};
</script>

<style></style>
