<template>
  <section id="CategoriesIndex">
    <app-page-title>
      <v-layout row justify-space-between align-end>
        <v-flex>
          <h1>Listagem de Anúncios</h1>
        </v-flex>
        <v-flex class="text-right">
          <app-form-modal
            class-icon="icon-add"
            :title-dialog="`${isNew ? 'Adicionar' : 'Editar'} Anúncio`"
            :confirmAction="saveInstance"
            :cancelAction="clearData"
            :formValid="!$v.Instance.$error"
            ref="modal"
          >
            <v-layout row wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="Instance.Title"
                  :error="$v.Instance.Title.$error"
                  @blur="$v.Instance.Title.$touch()"
                  label="Nome do Anúncio"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field v-model="Instance.URL" label="URL"></v-text-field>
              </v-flex>
              <v-flex xs6 v-if="!isNew">
                <app-date-picker
                  v-model="Instance.DatePublished"
                  title="Data de Publicação do anúncio"
                ></app-date-picker>
              </v-flex>
              <div v-if="!isNew">
                <v-flex xs6>
                  <v-label>Imagem</v-label>
                  <kendo-upload
                    ref="upload"
                    :multiple="false"
                    name="file"
                    :validation-allowed-extensions="['.png', '.jpg', '.jpeg']"
                    :select="onSelectFile"
                  ></kendo-upload>
                </v-flex>
                <v-flex xs12>
                  <v-label>Descrição</v-label>
                  <kendo-editor
                    style="height: 280px"
                    v-model="Instance.Description"
                  ></kendo-editor>
                </v-flex>
              </div>
            </v-layout>
          </app-form-modal>
        </v-flex>
      </v-layout>
    </app-page-title>
    <v-container>
      <Grid
        :style="{ height: 'auto' }"
        :data-items="Items"
        :columns="Columns"
        @edit="edit"
        @remove="remove"
      >
        <template slot="photoCell" slot-scope="{ props }">
          <td :class="props.className">
            <img
              v-if="props.dataItem.PhotoSrc"
              :src="
                GetRelativePath(props.dataItem.PhotoSrc, 'w=75&h=75&mode=crop')
              "
              class="rounded--circle img-fluid"
            />
            <img
              v-else
              src="/assets/imgs/no-photo.png?w=75&h=75&mode=crop"
              class="rounded--circle img-fluid"
            />
          </td>
        </template>
        <GridNoRecords>Não existem utilizadores</GridNoRecords>
      </Grid>
    </v-container>
  </section>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import * as kendo from "@progress/kendo-ui";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import noticeService from "@/services/api/noticeService";
import FormModalComponent from "@/components/modals/form-modal-large";
import EditCommandCell from "@/components/grid/edit-command.js";
import DeleteCommandCell from "@/components/grid/delete-command.js";
import UploadHelper from "@/utilities/upload";
import DatePickerComponent from "@/components/forms/date-picker";
import UrlBuilder from "@/utilities/url-builder";

export default {
  components: {
    "app-form-modal": FormModalComponent,
    "app-date-picker": DatePickerComponent,
  },
  data() {
    return {
      Instance: {},
      Items: [],
      Columns: [
        { field: "PhotoSrc", title: "Foto", cell: "photoCell", width: 80 },
        { field: "Title", title: "Título" },
        {
          cell: EditCommandCell,
          filterable: false,
          sortable: false,
          width: 75,
          title: "",
        },
        {
          cell: DeleteCommandCell,
          filterable: false,
          sortable: false,
          width: 75,
          title: "",
        },
      ],
    };
  },
  validations: {
    Instance: {
      Title: {
        required,
        minLength: minLength(3),
      },
    },
  },
  computed: {
    isNew() {
      return this.Instance != null && this.Instance.IdNotice == 0;
    },
  },
  methods: {
    GetRelativePath(path, query) {
      return `${UrlBuilder.GetPath(path)}${query ? "?" + query : ""}`;
    },
    onSelectFile(evt) {
      UploadHelper.LoadFile(evt)
        .then((file) => (this.Instance.Photo = file))
        .catch((file) => (this.Instance.Photo = file));
    },
    openModal() {
      this.$refs.modal.open();
    },
    deleteInstance(item) {
      var id = item.IdNotice;
      kendo.confirm("Tem a certeza que quer apagar o anúncio?").done(() => {
        noticeService
          .Delete(id)
          .then(() => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Apagou o anúncio com sucesso!"
            );
            this.Items = this.Items.filter((s) => s.IdNotice !== id);
            this.resetInstance();
          })
          .catch((error) => {
            notificationServiceBus.showError(error.response);
          });
      });
    },
    edit(item) {
      this.Instance = { ...item.dataItem };
      this.openModal();
    },
    remove(item) {
      this.deleteInstance(item.dataItem);
    },
    resetInstance() {
      this.Instance = {
        IdLanguage: 1,
        IdNotice: 0,
        Title: "",
        Description: "",
        Photo: null,
      };
    },
    clearData(callBack) {
      this.resetInstance();
      callBack(true);
    },
    async loadItems() {
      let options = {
        Filters: [],
        Order: [],
      };
      await noticeService
        .Get(options)
        .then((res) => {
          this.Items = res.data.Items;
          this.resetInstance();
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    async saveInstance(callBack) {
      if (this.isNew) {
        await noticeService
          .Create(this.Instance)
          .then((response) => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Criou o anúncio com sucesso!"
            );
            this.Items.push(response.data.Instance);
            this.resetInstance();
            callBack(true);
          })
          .catch((error) => {
            callBack(false);
            notificationServiceBus.showError(error.response);
          });
      } else {
        await noticeService
          .Update(this.Instance)
          .then((response) => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Editou o anúncio com sucesso!"
            );
            const index = this.Items.findIndex(
              (s) => s.IdNotice == this.Instance.IdNotice
            );
            if (index >= 0) {
              this.Items[index] = response.data.Instance;
            }
            this.resetInstance();
            callBack(true);
          })
          .catch((error) => {
            callBack(false);
            notificationServiceBus.showError(error.response);
          });
      }
    },
  },
  async created() {
    this.resetInstance();
    this.$v.$touch();
    await this.loadItems();
  },
};
</script>

<style></style>
