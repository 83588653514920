import axios from "axios";
import { API_URL } from "@/settings";
import * as types from "@/store/types";

const baseUrl = API_URL + "api/notifications";

const instance = axios.create();

const endPoints = {
  Courses: baseUrl + "/courses",
};

// eslint-disable-next-line prettier/prettier
instance.defaults.headers.common['Authorization'] = `Bearer ${window.localStorage.getItem(types.GLOBAL_USER_TOKEN)}`
instance.defaults.headers.common["Content-Type"] = "application/json";

const actions = {
  Courses(command) {
    return instance.post(endPoints.Courses, command);
  },
};

export default actions;
