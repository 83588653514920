<template>
  <div>
    <v-container>
      <h1>Dashboard</h1>
      <v-layout row wrap class="py-3">
        <v-flex xs12 sm12 md12 px-3>
          <v-card>
            <v-card-title></v-card-title>
            <v-card-text>
              <v-select
                item-text="Name"
                :items="courses"
                v-model="idCourse"
                item-value="IdCourse"
                :clearable="false"
                label="Cursos"
                class="mr-3"
              ></v-select>
              <v-btn
                color="accent"
                @click.prevent="submit"
                :isLoading="isLoading"
                >Selecionar</v-btn
              >
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout row wrap v-if="loadedPie">
        <v-flex xs12 sm12 md6 px-3>
          <v-card>
            <v-card-title
              >Distribuição de Respostas | Total:&nbsp;
              <strong> {{ totalAnswers }} </strong></v-card-title
            >
            <v-card-text>
              <kendo-chart
                :legend-position="'bottom'"
                :series="answeredQuestionsPieChart.series"
                :series-defaults-labels-visible="true"
                :series-defaults-labels-position="'insideEnd'"
                :series-defaults-labels-template="
                  answeredQuestionsPieChart.labelTemplate
                "
                :theme="'material'"
              ></kendo-chart>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12 sm12 md6 px-3>
          <v-card>
            <v-card-title
              >Número de respostas por grupo | Total:&nbsp;
              <strong>
                {{ answeredQuestionsPieChart.series[0].data[0].value }}
              </strong></v-card-title
            >
            <v-card-text>
              <kendo-chart
                :legend-position="'bottom'"
                :series="answersByUserGroupPieChart.series"
                :series-defaults-labels-visible="true"
                :series-defaults-labels-position="'insideEnd'"
                :series-defaults-labels-template="
                  answersByUserGroupPieChart.labelTemplate
                "
                :theme="'material'"
              ></kendo-chart>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12 sm12 md12 px-3 mt-3>
          <v-card>
            <v-card-title>Grau de satisfação</v-card-title>
            <v-card-text>
              <kendo-chart
                :title-text="'Média de respostas'"
                :legend-visible="false"
                :series-defaults-type="'column'"
                :series="QuestionDataBarChart.series"
                :category-axis="QuestionDataBarChart.categoryAxis"
                :value-axis="QuestionDataBarChart.valueAxis"
                :tooltip="QuestionDataBarChart.tooltip"
                :theme="'material'"
              ></kendo-chart>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import surveyService from "@/services/api/surveyService";
export default {
  props: { survey: { type: Object, default: null } },
  data() {
    return {
      courses: null,
      idCourse: 0,
      isLoading: false,
      loadedPie: false,
      answeredQuestionsPieChart: {
        series: [
          {
            type: "pie",
            data: [
              {
                category: "Inquéritos respondidos",
                value: 0,
              },
              {
                category: "Inquéritos por responder",
                value: 0,
              },
            ],
          },
        ],
        labelTemplate: "#= category #: #= value #",
      },
      answersByUserGroupPieChart: {
        series: [
          {
            type: "pie",
            data: [],
          },
        ],
        labelTemplate: "#= category #: #= value #",
      },
      QuestionDataBarChart: {
        series: [
          {
            name: "Média de respostas",
            data: [],
          },
        ],
        valueAxis: [
          {
            max: 5,
            min: 0,
            visible: true,
            line: {
              visible: false,
            },
            minorGridLines: {
              visible: false,
            },
            labels: {
              rotation: "auto",
            },
          },
        ],
        categoryAxis: {
          categories: [],
          majorGridLines: {
            visible: false,
          },
        },
        tooltip: {
          visible: true,
          template: "#= series.name #: #= value #",
        },
      },
    };
  },
  created() {
    let options = {
      Filters: [],
      Order: [],
    };
    surveyService
      .GetCoursesWithSurvey(this.survey.IdSurvey, options)
      .then((response) => {
        this.courses = response.data.Items;
        this.idCourse = this.courses[0].IdCourse;
        this.isLoading = true;
      });
  },
  computed: {
    totalAnswers: function () {
      return (
        this.answeredQuestionsPieChart.series[0].data[0].value +
        this.answeredQuestionsPieChart.series[0].data[1].value
      );
    },
  },
  methods: {
    clearData() {
      this.answeredQuestionsPieChart.series[0].data[0].value = 0;
      this.answeredQuestionsPieChart.series[0].data[1].value = 0;
      this.answersByUserGroupPieChart.series[0].data = [];
      this.QuestionDataBarChart.series[0].data = [];
      this.QuestionDataBarChart.categoryAxis.categories = [];
    },
    submit() {
      this.loadedPie = false;
      let options = {
        Filters: [],
        Order: [],
      };
      this.clearData();
      surveyService
        .GetSurveyStatistics(this.idCourse, this.survey.IdSurvey, options)
        .then((res) => {
          //Data for the first Graph
          this.answeredQuestionsPieChart.series[0].data[0].value =
            res.data.SurveyResponseData.SubscriptionsWithCompletedSurvey;

          this.answeredQuestionsPieChart.series[0].data[1].value =
            res.data.SurveyResponseData.SubscriptionsWithPendingSurvey;

          //Data for the second graph
          for (let key in res.data.UserGroupsOfCompletedSurveyData) {
            this.answersByUserGroupPieChart.series[0].data.push({
              category: key,
              value: res.data.UserGroupsOfCompletedSurveyData[key],
            });
          }

          for (let key in res.data.QuestionsData) {
            this.QuestionDataBarChart.series[0].data.push(
              res.data.QuestionsData[key].toFixed(2)
            );
            this.QuestionDataBarChart.categoryAxis.categories.push(key);
          }
          this.loadedPie = true;
        });
    },
  },
};
</script>
