<template>
  <v-container>
    <app-inscriptions-grid :course="course"></app-inscriptions-grid>
  </v-container>
</template>

<script>
import SubscriptionsGridComponent from "@/components/grid/subscriptions.grid";

export default {
  components: {
    "app-inscriptions-grid": SubscriptionsGridComponent,
  },
  props: {
    course: { type: Object, required: true },
  },
};
</script>

<style scoped></style>
