export const FormationDomains = [
  {
    Code: "01",
    Text: "COMÉRCIO, MARKETING E PUBLICIDADE",
  },
  {
    Code: "02",
    Text: "GESTÃO",
  },
  {
    Code: "03",
    Text: "DESENVOLVIMENTO PESSOAL/RH",
  },
  {
    Code: "04",
    Text: "ENQUADRAMENTO NA ORGANIZAÇÃO / EMPRESA",
  },
  {
    Code: "05",
    Text: "AMBIENTE",
  },
  {
    Code: "05",
    Text: "SEGURANÇA E SAÚDE NO TRABALHO",
  },
  {
    Code: "06",
    Text: "LINGUAS",
  },
  {
    Code: "07",
    Text: "INFORMÁTICA",
  },
  {
    Code: "08",
    Text: "ALIMENTAR",
  },
  {
    Code: "09",
    Text: "LINGUAS",
  },
  {
    Code: "10",
    Text: "OUTROS",
  },
];

export function GetTypeTextById(id) {
  for (let index = 0; index < FormationDomains.length; index++) {
    if (FormationDomains[index].Code === id) {
      return FormationDomains[index].Text;
    }
  }
  return "";
}
