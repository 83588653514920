<template>
  <v-container grid-list v-if="course">
    <app-modal-module
      ref="moduleModal"
      :module="Instance"
      @onCreated="moduleCreated"
      @onEdited="moduleEdited"
    ></app-modal-module>
    <div class="k-grid k-grid-toolbar">
      <v-btn color="accent" title="Adicionar" @click.prevent="addModule"
        >Adicionar Módulo</v-btn
      >
    </div>
    <Grid
      :id="gridIdentifier"
      :style="{ height: 'auto' }"
      :data-items="Modules"
      :columns="Columns"
      :detail="cellTemplate"
      @expandchange="expandChange"
      :expand-field="'expanded'"
      @edit="edit"
      @remove="remove"
    >
      <GridNoRecords> Não existem módulos </GridNoRecords>
    </Grid>
  </v-container>
</template>

<script>
import Vue from "vue";
import Sortable from "sortablejs";
import * as kendo from "@progress/kendo-ui";
import ModalModuleComponent from "../shared/modal-module";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import courseModulesService from "@/services/api/courseModulesService";
import { CourseContentTypes } from "@/models/course-content-types";
import EditCommandCell from "@/components/grid/edit-command.js";
import DeleteCommandCell from "@/components/grid/delete-command.js";
import OrderCommandCell from "@/components/grid/order-command.js";
import { VTabs, VTab, VTabItem } from "vuetify/lib";

import ContentsGridComponent from "../shared/contents.grid";
import DocumentsGridComponent from "../shared/documents.grid";
Vue.component("app-contents-grid", ContentsGridComponent);
Vue.component("app-documents-grid", DocumentsGridComponent);
const componentInstance = Vue.component("app-contents-grid-component", {
  components: {
    VTabs,
    VTab,
    VTabItem,
  },
  props: ["dataItem"],
  data() {
    return {
      tab: "contents",
    };
  },
  template: `<v-tabs v-model="tab" slider-color="primary">
              <v-tab :key="'contents'">Conteúdo Programático</v-tab>
              <v-tab :key="'documents'">Documentos auxiliares</v-tab>
              <v-tab-item :key="'contents'"><app-contents-grid :moduleId="dataItem.IdCourseModule"></app-contents-grid></v-tab-item>
              <v-tab-item :key="'documents'"><app-documents-grid :moduleId="dataItem.IdCourseModule"></app-documents-grid></v-tab-item>
              </v-tabs>`,
});

export default {
  components: {
    "app-modal-module": ModalModuleComponent,
  },
  props: ["course"],
  data() {
    return {
      Instance: {},
      cellTemplate: componentInstance,
      Modules: [],
      Columns: [
        {
          cell: OrderCommandCell,
          filterable: false,
          sortable: false,
          width: 75,
          title: "Ordem",
        },
        { field: "Name", title: "Nome do Módulo" },
        {
          cell: EditCommandCell,
          filterable: false,
          sortable: false,
          width: 75,
          title: "",
        },
        {
          cell: DeleteCommandCell,
          filterable: false,
          sortable: false,
          width: 75,
          title: "",
        },
      ],
    };
  },
  computed: {
    courseContentTypes() {
      return CourseContentTypes;
    },
    gridIdentifier() {
      return `GridCourse${this.course.IdCourse}`;
    },
  },
  watch: {
    "course.Modules"() {
      this.Modules = this.course.Modules;
    },
  },
  methods: {
    addModule() {
      this.$refs.moduleModal.open();
    },
    moduleCreated(item) {
      this.course.Modules.push(item);
      this.$emit("modulesChanged", this.course.Modules);
    },
    moduleEdited(item) {
      const index = this.course.Modules.findIndex(
        (x) => x.IdCourseModule === item.IdCourseModule
      );
      this.course.Modules[index] = item;
      this.$emit("modulesChanged", this.course.Modules);
    },
    resetInstance() {
      this.Instance = {
        IdCourseModule: 0,
        IdCourse: this.course.IdCourse,
        Name: "",
        Description: "",
        TypeId: 1,
      };
    },
    edit(item) {
      this.Instance = item.dataItem;
      this.addModule();
    },
    remove(item) {
      this.deleteModule(item.dataItem);
    },
    deleteModule(item) {
      var id = item.IdCourseModule;
      kendo.confirm("Tem a certeza que quer apagar o módulo?").done(() => {
        courseModulesService
          .Delete(id)
          .then(() => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Apagou o módulo com sucesso!"
            );
            this.$emit(
              "modulesChanged",
              this.Modules.filter((s) => s.IdCourseModule !== id)
            );
          })
          .catch((error) => {
            notificationServiceBus.showError(error.response);
          });
      });
    },
    expandChange(ev) {
      Vue.set(ev.dataItem, ev.target.$props.expandField, ev.value);
    },
    orderModules(items) {
      courseModulesService
        .UpdateOrder(
          this.course.IdCourse,
          items.map((s) => s.IdCourseModule)
        )
        .then(() => {
          notificationServiceBus.showSuccessMessage(
            "Successo!",
            "alterou a ordem com sucesso!"
          );
          this.Modules = [...items];
          //this.loadItems();
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
  },
  created() {
    this.Modules = this.course.Modules;
    this.resetInstance();
  },
  mounted() {
    var that = this;
    var element = `${this.gridIdentifier}-Table`;
    this.$el
      .querySelector(
        `#${this.gridIdentifier} > .k-grid-container > .k-grid-content > div > .k-grid-table > tbody`
      )
      .setAttribute("id", element);
    Sortable.create(document.getElementById(element), {
      handle: ".k-action-move",
      animation: 150,
      onEnd: function (evt) {
        var arr = [...that.Modules];
        var old = arr[evt.oldIndex];
        arr = arr
          .slice(0, evt.oldIndex)
          .concat(arr.slice(evt.oldIndex + 1, arr.length));
        arr.splice(evt.newIndex, 0, old);
        that.Modules = [...[]];
        that.orderModules(arr);
      },
    });
  },
};
</script>

<style>
.v-tabs__item {
  display: grid !important;
}
</style>
