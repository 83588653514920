<template>
  <kendo-dropdownlist v-model="current" :data-source="Items" :data-text-field="'Name'" :data-value-field="'TypeId'" :optionLabel="label" @select="onChanged" />
</template>
<script>
import { CoursePlataformTypes } from "@/models/course-plataform-types";

export default {
  props: ["value"],
  watch: {
    value () {
      this.current = this.value;
    },
  },
  data () {
    return {
      current: this.value,
      label: {
        TypeId: null,
        Name: "Plataforma..",
      },
      Items: CoursePlataformTypes,
    };
  },
  methods: {
    onChanged (e) {
      this.$emit("input", e.dataItem.TypeId);
    },
  },
};
</script>
