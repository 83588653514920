<template>
  <div>
    <app-form-modal :title-dialog="`Gerir presenças`" :allowButton="false" :confirmAction="saveAttendance" ref="modalAttendance">
      <div class="custom-grid">
        <div class="mb-2">
          Marcar os alunos inscritos na ação de formação como presentes:
          <a @click.prevent="onMarkAllClick">clique aqui</a>
          <button color="accent" class="k-button k-button-icontext my-2" @click="exportExcel()">
            Exportar para excel
          </button>
        </div>

        <Grid ref="attendanceGrid" :style="{ height: '400px' }" :filterable="true" @filterchange="filterChangeAttendance" :data-items="availableItemsAttendance" :columns="ColumnsAttendance">
          <template slot="changeCell" slot-scope="{ props }">
            <td class="text-center">
              <v-checkbox v-model="props.dataItem.WasPresent" @change="onPresenceChange(props.dataItem)"></v-checkbox>
            </td>
          </template>
          <GridNoRecords>Não existem inscrições</GridNoRecords>
        </Grid>
      </div>
    </app-form-modal>
    <div class="k-grid k-grid-toolbar">
      <app-form-modal :title-dialog="`${isNew ? 'Adicionar' : 'Editar'} Sessão`" :title-button="'Adicionar sessão'" :confirmAction="saveInstance" :cancelAction="clearData" :formValid="!$v.Instance.$error &&
      Instance.Name.length > 2 &&
      Instance.Minutes <= maxInstanceMinutes
      " @opened="
      maxInstanceMinutes =
      courseClass.Minutes - totalMinutes + Instance.Minutes
      " ref="modal">
        <div class="custom-grid">
          <v-layout wrap row>
            <v-flex xs12>
              <v-text-field label="Nome da sessão" v-model="Instance.Name"></v-text-field>
            </v-flex>
            <v-flex xs12 v-if="this.courseClass.DateEnd != null &&
      Instance.Date > this.courseClass.DateEnd
      ">
              <v-alert color="warning" :value="true">
                Não pode inserir uma data superior à da ação de formação
              </v-alert>
            </v-flex>
            <v-flex xs6>
              <datetime-picker v-model="Instance.Date" label="Data"></datetime-picker>
            </v-flex>
            <v-flex xs6>
              <v-text-field label="Horas" type="number" v-model="hours"></v-text-field>
            </v-flex>
            <v-flex xs12 v-if="Instance.Minutes > maxInstanceMinutes">
              <v-alert color="warning" :value="true">
                Não pode inserir mais do que
                {{ maxInstanceMinutes }} minutos.
              </v-alert>
            </v-flex>
            <v-flex xs12>
              <v-select label="Metodologia de ensino" :items="CourseArchitectureTypes" item-text="Name" item-value="TypeId" v-model="Instance.TypeId"></v-select>
            </v-flex>
            <v-flex xs12>
              <v-text-field label="Local" v-model="Instance.Location"></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-textarea v-model="Instance.Description" label="Sumário"></v-textarea>
            </v-flex>
          </v-layout>
        </div>
      </app-form-modal>
      <span class="ml-5">
        Registado {{ totalMinutes }} de {{ courseClass.Minutes }} minutos de
        Formação nas sessões.
      </span>
    </div>
    <Grid ref="sessionsGrid" :style="{ height: 'auto' }" :data-items="Items" :columns="Columns" @edit="edit" @remove="remove">
      <template slot="attendanceCell" slot-scope="{ props }">
        <td :class="props.className">
          <button class="k-button k-button-icontext" @click="attendanceHandler(props.dataItem)">
            <span class="icon-user"></span>
          </button>
        </td>
      </template>
      <GridNoRecords>Não existem sessões</GridNoRecords>
    </Grid>
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import * as kendo from "@progress/kendo-ui";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import courseSessionsService from "@/services/api/courseSessionsService";
import coursePresencesService from "@/services/api/coursePresencesService";
import DateTimePicker from "@/components/forms/date-time-picker";
import FormModalComponent from "@/components/modals/form-modal";
import EditCommandCell from "@/components/grid/edit-command.js";
import DeleteCommandCell from "@/components/grid/delete-command.js";
import StringFilterCell from "@/components/grid/string-filter.js";
import { CourseArchitectureTypes } from "@/models/course-architecture-types";
import { saveExcel } from '@progress/kendo-vue-excel-export';
export default {
  components: {
    "app-form-modal": FormModalComponent,
    "datetime-picker": DateTimePicker,
  },
  props: { courseClass: { type: Object, required: true } },
  data () {
    return {
      hours: 0,
      currentIdCourseSession: 0,
      CourseArchitectureTypes: CourseArchitectureTypes,
      maxInstanceMinutes: 0,
      Instance: {
        Date: this.courseClass.DateStart,
        TypeId: this.courseClass.TypeId,
      },
      filterAttendance: [],
      ItemsAttendance: [],
      ColumnsAttendance: [
        {
          field: "User.Code",
          title: "Código",
          width: 125,
          filterCell: StringFilterCell,
        },
        {
          field: "User.Name",
          title: "Nome",
          filterCell: StringFilterCell,
        },
        {
          cell: "changeCell",
          filterable: false,
          sortable: false,
          width: 100,
          title: "Presença",
        },
      ],
      Items: [],
      Columns: [
        { field: "Name", title: "Nome" },
        { field: "Location", title: "Local" },
        {
          cell: "attendanceCell",
          filterable: false,
          sortable: false,
          width: 75,
          title: "",
        },
        {
          cell: EditCommandCell,
          filterable: false,
          sortable: false,
          width: 75,
          title: "",
        },
        {
          cell: DeleteCommandCell,
          filterable: false,
          sortable: false,
          width: 75,
          title: "",
        },
      ],
    };
  },
  validations: {
    Instance: {
      Name: {
        required,
        minLength: minLength(3),
      },
    },
  },
  computed: {
    isNew () {
      return this.Instance != null && this.Instance.IdCourseSession == 0;
    },
    totalMinutes () {
      return this.Items.reduce((a, b) => a + (b.Minutes || 0), 0);
    },
    availableMinutes () {
      return this.courseClass.Minutes - this.totalMinutes;
    },
    availableItemsAttendance () {
      var innerFilters = this.filterAttendance.filter((s) => s.value);
      if (innerFilters.length == 0) return this.ItemsAttendance;
      return this.ItemsAttendance.filter((s) => {
        for (let index = 0; index < innerFilters.length; index++) {
          const f = innerFilters[index];
          if (
            s.User[f.field.split(".")[1]]
              .toLowerCase()
              .includes(f.value.toLowerCase())
          )
            return true;
        }
        return false;
      });
    },
  },
  watch: {
    hours (val) {
      if (this.Instance) {
        this.Instance.Minutes = parseInt(val * 60);
      }
    },
  },
  methods: {
    exportExcel () {
      const data = this.availableItemsAttendance.map(item => {
        const presence = item.WasPresent ? 'Presente' : 'Ausente';

        return {
          'Código': item.User.Code,
          'Nome': item.User.Name,
          'Presença': presence
        };
      });

      saveExcel({
        data: data,
        fileName: "myFile",
        columns: [
          { field: 'Código', title: 'Código' },
          { field: 'Nome', title: 'Nome' },
          { field: 'Presença', title: 'Presença' }
        ]
      });
    },
    deleteInstance (item) {
      var id = item.IdCourseSession;
      kendo.confirm("Tem a certeza que quer apagar a sessão?").done(() => {
        courseSessionsService
          .Delete(id)
          .then(() => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Apagou o anúncio com sucesso!"
            );
            this.Items = this.Items.filter((s) => s.IdCourseSession !== id);
            this.resetInstance();
          })
          .catch((error) => {
            notificationServiceBus.showError(error.response);
          });
      });
    },
    edit (item) {
      this.Instance = { ...item.dataItem };
      this.hours = this.Instance.Minutes / 60;
      this.maxInstanceMinutes =
        this.courseClass.Minutes - this.totalMinutes + this.Instance.Minutes;
      this.$refs.modal.open();
    },
    remove (item) {
      this.deleteInstance(item.dataItem);
    },
    async attendanceHandler (item) {
      this.currentIdCourseSession = item.IdCourseSession;
      await this.loadPresences(item.IdCourseSession);
      this.$refs.modalAttendance.open();
    },
    saveAttendance (callback) {
      callback(true);
    },
    resetInstance () {
      this.Instance = {
        IdCourseSession: 0,
        IdCourseClass: this.courseClass.IdCourseClass,
        Name: "",
        Date: this.courseClass.DateStart,
        TypeId: this.courseClass.TypeId,
        Minutes: 0,
      };
      this.maxInstanceMinutes = this.courseClass.Minutes - this.totalMinutes;
      this.Instance.Minutes =
        this.maxInstanceMinutes > 240 ? 240 : this.maxInstanceMinutes;
      this.hours = this.Instance.Minutes / 60;
    },
    clearData (callBack) {
      this.resetInstance();
      callBack(true);
    },
    filterChangeAttendance (ev) {
      var f = this.filterAttendance.find((s) => s.field == ev.event.field);
      if (!f) {
        f = { field: ev.event.field, value: "" };
        this.filterAttendance.push(f);
      }
      if (ev.filter) {
        f.value = ev.filter.filters[0].value;
      } else {
        f.value = "";
      }
    },
    async onPresenceChange (item) {
      await coursePresencesService.Update(
        item.CourseSession.IdCourseSession,
        item.IdUserCourseSubscription,
        item.WasPresent
      );
    },
    async onMarkAllClick () {
      await coursePresencesService
        .Update(this.currentIdCourseSession, null, true)
        .then(() => {
          this.loadPresences(this.currentIdCourseSession);
        });
    },
    async saveInstance (callBack) {
      if (this.isNew) {
        await courseSessionsService
          .Create(this.Instance)
          .then((response) => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Criou a sessão com sucesso!"
            );
            this.Items.push(response.data.Instance);
            this.resetInstance();
            callBack(true);
          })
          .catch((error) => {
            callBack(false);
            notificationServiceBus.showError(error.response);
          });
      } else {
        await courseSessionsService
          .Update(this.Instance)
          .then(() => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Editou a sessão com sucesso!"
            );
            this.loadSessions();
            this.resetInstance();
            callBack(true);
          })
          .catch((error) => {
            callBack(false);
            notificationServiceBus.showError(error.response);
          });
      }
    },
    async loadSessions () {
      await courseSessionsService
        .Get({
          Filters: [
            {
              Field: "IdCourseClass",
              Operator: "eq",
              Value: this.courseClass.IdCourseClass,
            },
          ],
        })
        .then((response) => {
          this.Items = response.data.Items.map((item) =>
            Object.assign(
              {
                inEdit: false,
              },
              item
            )
          );
          this.maxInstanceMinutes =
            this.courseClass.Minutes -
            this.totalMinutes +
            this.Instance.Minutes;
        });
    },
    async loadPresences (idCourseSession) {
      await coursePresencesService
        .Get(this.courseClass.IdCourseClass, idCourseSession)
        .then((response) => {
          this.ItemsAttendance = response.data.map((item) =>
            Object.assign(
              {
                inEdit: false,
              },
              item
            )
          );
        });
    },
  },
  async created () {
    this.resetInstance();
    this.$v.$touch();
    await this.loadSessions();
  },
};
</script>
